import { routeReducer, RouteState } from './reducers/route';
import { userReducer, ProfileState } from './reducers/user';
import { usersReducer, UserState } from './reducers/users';
import { idbReducer, IDBState } from './reducers/idb';
import { appReducer, AppState } from './reducers/app';
import { fileReducer, FileState } from './reducers/file.js';

export interface State {
  route: RouteState,
  user: ProfileState,
  users: UserState[],
  idb: IDBState,
  app: AppState,
  file: FileState,
}

function combineReducers(reducers: Record<string, (state: {}, action: any) => {}>) {
  const keys = Object.keys(reducers);
  return function combination(state = {}, action: any) {
    const nextState = {};

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const reducer = reducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(previousStateForKey, action);
      nextState[key] = nextStateForKey;
    }
    return nextState;
  };
}

const rootReducer = combineReducers({
  route: routeReducer,
  user: userReducer,
  users: usersReducer,
  idb: idbReducer,
  app: appReducer,
  file: fileReducer,
  //domain: domainReducer,
});

export default rootReducer;
