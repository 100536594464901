/**
 * v1
 * v1.1 added getDataFromElements
 */
interface ElementOptions {
  className?: string;
  id?: string;
  attrs?: { [index: string]: string };
  props?: { [index: string]: unknown };
  value?: unknown;
  text?: string;
  required?: boolean;
  onsubmit?: () => void;
  parent?: HTMLElement | ShadowRoot;
}
export function create(type: string, o: ElementOptions = {}) {
  const elem = document.createElement(type);
  if (o.className) elem.className = o.className;
  if (o.id) elem.id = o.id;
  if (o.attrs) Object.keys(o.attrs).forEach((key) => elem.setAttribute(key, o.attrs[key]));
  if (o.props) Object.keys(o.props).forEach((key) => (elem[key] = o.props[key]));
  if (o.text) elem.textContent = o.text;
  if (o.onsubmit) elem.onsubmit = o.onsubmit;

  //@ts-ignore
  if (o.value) elem.value = o.value;

  if (o.parent) o.parent.appendChild(elem);
  else document.body.appendChild(elem);

  return elem;
}

export function remove(type, o: ElementOptions = {}) {
  const { parent = document.body, required = false } = o;
  const elem = parent.querySelector(type);
  if (elem) elem.remove();
  else if (required) elog(`${type} not found in dom.remove:33`);
}

export function update(view) {
  const elem = document.querySelector(view);
  if (elem) elem.updateView();
  else elog(`${view} not found in dom.update:42`);
}

export function getDataFromElements(nodes = []) {
  const result = {};

  if (!Array.isArray(nodes)) nodes = [nodes];
  else nodes = nodes.filter(Boolean);

  for (const el of nodes) {
    for (const key in el.dataset) {
      const value = el.dataset[key];
      result[key] = isNaN(value) ? value : +value;
    }
  }
  return result;
}
