import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { StateController } from 'controller/state';
import { router } from 'helpers/components';
import { debounce } from 'helpers/utils';
import { openFile, save } from 'logic/pwa/file-system';
import { closeFile, viewFile } from 'state/actions/file';
import { FileState } from 'state/reducers/file';

const category = 'app-titlebar';

declare global {
  interface HTMLElementTagNameMap {
    [category]: AppTitlebar;
  }
}

@customElement(category)
class AppTitlebar extends LitElement {
  private state = new StateController(this);

  @state() _isVisible: boolean;
  @state() url: string;
  @state() route: Element;
  @state() _openMenu: string;
  @state() _fileName: string;
  @state() file: FileState;

  private _onGeometryChange: (e: Event & WindowControlsOverlayContainer) => void;

  private _onMenuOpen(e: CustomEvent) {
    this._openMenu = e.detail;
  }

  constructor() {
    super();

    this.addEventListener('menu', (e) => this._onMenuOpen(e));

    const overlayIsVisible = (e: Event & WindowControlsOverlayContainer) => this._toggleTitlebar();
    this._onGeometryChange = debounce(overlayIsVisible, 100);
  }

  private _toggleTitlebar() {
    let isVisible = false;

    if ('windowControlsOverlay' in navigator) {
      const isVisible = navigator.windowControlsOverlay.visible;
    }

    if (this._isVisible === isVisible) {
      console.log('_toggleTitlebar no change');
    } else {
      this._isVisible = isVisible;
    }

    if (this.url === '/file/' || this._fileName) return;

    if (isVisible) {
      document.documentElement.style.setProperty('--titlebar-height-fallback', '32px');
      this.removeAttribute('disabled');
    } else {
      document.documentElement.style.setProperty('--titlebar-height-fallback', '0px');
      this.setAttribute('disabled', '');
    }
  }
  protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
    if (_changedProperties.has('_fileName')) {
      this._toggleTitlebar();
    }
  }

  stateChanged(state) {
    this._fileName = state.file.name;
    this.file = state.file;
    this.url = state.route.url;
    this.route = state.route.view;
  }

  connectedCallback() {
    super.connectedCallback();
    if ('windowControlsOverlay' in navigator) {
      this._isVisible = navigator.windowControlsOverlay.visible;
      navigator.windowControlsOverlay.addEventListener('geometrychange', this._onGeometryChange);
    }
    if (this.url === '/file/') document.documentElement.style.setProperty('--titlebar-height-fallback', '32px');
  }
  protected firstUpdated(_changedProperties: Map<string | number | symbol, unknown>): void {
    const el = <HTMLElement>document.querySelector('app-state');
    el.style.setProperty('transition', 'border 500ms ease-in-out');
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    if ('windowControlsOverlay' in navigator) {
      navigator.windowControlsOverlay.removeEventListener('geometrychange', this._onGeometryChange);
    }
  }

  private _onClickCommandBar({ target }) {
    const { action } = target.dataset;
    switch (action) {
      case 'colorScheme':
        //return this.colorScheme.toggle();
    }
  }

  private async _handleMenuClick({ target }) {
    const { action, type, path } = target.dataset;
    if (!action) return;

    const el = document.body.querySelector('file-view');

    switch (action) {
      case 'open':
        const file = await openFile();
        file.rows = new Map(file.rows);
        return this.state.dispatch('file', viewFile(file));
      case 'close':
        return this.state.dispatch('app', closeFile());
      case 'save':
      case 'saveAs':
        console.log(el, el.rota);
        const backup = await el.rota.backupRota('offline');
        return save(this._fileName, backup, this.file.handle, this.file);
      case 'route':
        console.log(path);

        return router.go(path);
      default:
        console.error('no action for', action);
    }
  }

  // <context-option data-action="save">Save </context-option>
  templateMenubar() {
    if (!this._fileName) return html`<span>Willkommen!</span>`;
    return html``;
  }
  render() {
    return html`
      <section class="menu-bar" @click="${this._handleMenuClick}">
        <menu-category id="titlebar-file--open" name="datei" ?open=${this._openMenu === 'titlebar-file--open'}>
          <app-button data-action="open">Öffnen</app-button>
          <app-button data-action="save" ?disabled=${!this._fileName}>Speichern</app-button>
          <app-button data-action="close" ?disabled=${!this._fileName}>Schließen</app-button>
        </menu-category>
        <menu-category id="titlebar-view--open" name="ansicht" ?open=${this._openMenu === 'titlebar-view--open'}>
          <app-button data-action="route" data-path="/file/" ?disabled=${!this._fileName}>Dienstplan</app-button>
          <app-button data-action="route" data-path="/tagesplan/" ?disabled=${!this._fileName}>Tagesplan</app-button>
        </menu-category>
      </section>
      <section class="title-bar center">${this._fileName}</section>
      <section class="command-bar center icons" @click="${this._onClickCommandBar}">

      </section>
    `;
  }

  static styles = css`
    :host {
      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      width: env(titlebar-area-width, 100%);
      min-height: env(titlebar-area-height, var(--titlebar-height-fallback));
      display: grid;
      grid-template-columns: 0fr 1fr 0fr;
      gap: 1rem;

      background: var(--theme, hsl(205deg 41% 7%));
      color: var(--theme-text, white);
      font-family: inherit;
      user-select: none;
      z-index: 3;
      -webkit-app-region: drag;
      app-region: drag;
    }
    :host([disabled]) {
      display: none;
    }
    section {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      -webkit-app-region: no-drag;
      app-region: no-drag;
      white-space: nowrap;
    }
    section.title-bar {
      font-size: 0.8rem;
      -webkit-app-region: drag;
      app-region: drag;
    }
    .center {
      place-content: center;
      align-items: center;
    }
    span {
      font-size: 0.8rem;
      padding: var(--space-xxs) var(--space-s);
    }

  `;
}
