import { findDuplicates } from 'helpers/arr';
import { sortByName } from 'helpers/sort';
import { UserState } from 'state/reducers/users';

export const ADD_USERS = 'ADD_USERS';

const noCriticalCare = new Set([
  '44429FD0AB5C11EBBB7B0F8EBBC98572', // Kühne
  '16569A00AB5B11EBB7961739EA2F949F', // Öz
  //'EF84DDC0A83411EBADEEB7AF8B734F60', // 'Schmitz'
]);

export function parseUsers(users: UserState[]) {
  const sorted = sortByName(users, 'name');

  const names = sorted.map((u) => u.name);
  const duplicateNames = findDuplicates(names);

  return sorted.map((user): UserState => {
    const hhd = noCriticalCare.has(user.id);

    const displayName =
      duplicateNames.has(user.name)
        ? `${user.name} ${user.first_name[0]}`
        : user.name;

    return Object.assign(user, { hhd, displayName });
  });
}
/**
 * Getting all users from idb
 * Can maybe add the idb logic here
 */
export function addUsers(users: UserState[]) {
  return { type: ADD_USERS, users: parseUsers(users) };
}
