import { UPDATE_IDB_STORE } from '../actions/idb.js';

export interface IDBState {
  rota: number;
}

const idb = {
  rota: undefined,
};
export function idbReducer(state = idb, action) {
  switch (action.type) {
    case UPDATE_IDB_STORE:
      return Object.assign({}, state, action.store);
    default:
      return state;
  }
}
