import IndexedDB from '@simonbluhm/idb';

const NAME = 'diakovere';
const VERSION = 2;
const SCHEMA = (oldVersion, db, tx) => {
  // tx required if object store already exists (add index)

  const create = (storeName, index, opts = { keyPath: 'id' }) => {
    const store = db.createObjectStore(storeName, opts);
    for (const name of index) {
      store.createIndex(name, name, { unique: false });
    }
  };
  const del = (storeName) => db.deleteObjectStore(storeName);
  const index = (storeName, index) => tx.objectStore(storeName).createIndex(index, index, { unique: false });

  switch (oldVersion) {
    case 0: {
      create('logs', [], { keyPath: 'id', autoIncrement: true });
      create('sync', []);
      create('settings', []);
      create('rota', ['timestamp', 'upd']);
      create('users', ['level', 'name']);
      create('handles', []);
    }
    case 1: {
      index('rota', 'bz');
    }
  }
  db.upgrade = true;
};
const BEACON_URL = '/api/v2/logs/';

const idb = new IndexedDB(NAME, VERSION, SCHEMA, BEACON_URL);

export default idb;
