import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import rootReducer, { State } from '../state/reducers.js';
import { validateToken, login } from '../state/actions/user';
import { addUsers } from '../state/actions/users.js';

import idb from 'logic/idb';

import { debounce, consoleCss } from 'helpers/utils';
import { loadColorScheme } from 'logic/pwa/color-scheme.js';

const category = 'app-state';

const EVENT_STATE_INCOMING = 'state';
const EVENT_STATE_UPDATED = 'stateUpdate';

const consoleStore = debounce((newState: State) => console.log('%c%s', consoleCss('store'), 'state', newState), 1000);

interface StateEvent extends CustomEvent{
  name: string,
  action: any,
}

@customElement(category)
class AppState extends LitElement {
  @state() _state: {};

  constructor() {
    super();
    const createStore = (reducer: (state: {}, action: any) => {}) => reducer(undefined, {});
    this._state = createStore(rootReducer);

    this._hydrateStore();

    this.addEventListener(EVENT_STATE_INCOMING, ({ name, action }: StateEvent) => this.dispatch(name, action));
  }
  private async _hydrateStore() {
    loadColorScheme();

    const users = await idb.getAll('users');
    this.dispatch('users', addUsers(users));


    const accessToken = sessionStorage.getItem('accessToken');
    if (validateToken(accessToken)) {
      this.dispatch('user', login());
    }

    /*
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken || validateToken(accessToken) === false) {
      try {
        await credentialManagement();
      } catch (err) {
        console.warn(err);
        signin.showLogin();
      }
    } else {
      this.dispatch('user', login());
    }
    */
  }

  _setState(newState: State) {
    this._state = newState;
    this.dispatchEvent(new Event(EVENT_STATE_UPDATED));
    consoleStore(newState);
  }
  dispatch(name: string, action: any) {
    console.log('%c%s', consoleCss('state'), name, action?.type, action);
    const reducers = [rootReducer];
    const newState = reducers.reduce((state, reducer) => {
      return reducer(state, action);
    }, this._state) as State;

    this._setState(newState);
  }

  // messenger.js
  getState() {
    return this._state;
  }
  // messenger.js
  subscribe(callback: () => void) {
    this.addEventListener(EVENT_STATE_UPDATED, callback);
    const removeListener = () => this.removeEventListener(EVENT_STATE_UPDATED, callback);
    return removeListener;
  }

  render() {
    return html`<slot></slot>`;
  }

  static styles = css`
    :host {
      display: block;
      border: 0px solid var(--theme);
      border-top-width: env(titlebar-area-height, var(--titlebar-height-fallback));
      height: 100vh;
      overflow-y: auto;
    }
  `;
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppState;
  }
}
