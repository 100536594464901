import { getUser } from "controller/server";
import { store } from "helpers/components";
import { login } from "state/actions/user";
import { Auth } from "types/server/auth";
import { tokenChannel } from "./bc-tokens";

const category = 'signin';

function handleError(res: Auth) {
  console.error({ res });
  switch (res.code) {
    case 'auth/wrong-password':
      return 'Falsches Passwort.';
    case 'auth/user-not-found':
      return 'Benutzername wurde nicht gefunden.';
    case 'auth/too-many-requests':
      return 'Benutzerdaten wurden zu oft falsch eingegeben. Bitte versuchen Sie es später nochmal.';
    default:
      return 'Unbekannter Fehler.'
  }
}

export async function emailSendRequest(cred: FormData | Credential) {
  const body = {
    username: null,
    password: null,
  }
  if (cred instanceof FormData) {
    body.username = cred.get('username');
    body.password = cred.get('password');
  } else {
    body.username = cred.id;
    body.password = cred.password;
  }

  try {
    const user = await getUser(body.username, body.password);
    if (!user || user.error) throw new Error(handleError(user));

    sessionStorage.setItem('accessToken', user.accessToken);
    sessionStorage.setItem('refreshToken', user.refreshToken);
    sessionStorage.setItem('context', 'primary');
    sessionStorage.setItem('encryptedKey', user.encryptedKey);
    sessionStorage.setItem('publicKey', user.publicKey);
    tokenChannel.postMessage({ action: 'tokens', access: user.accessToken, refresh: user.refreshToken });

    //@ts-ignore
    if (window.PasswordCredential && cred instanceof FormData) {
      console.log('method', { category, label: 'cm-store' });
      navigator.credentials.store(new PasswordCredential({
        id: body.username,
        password: body.password,
      }));
    } else {
      console.log('method', { category, label: 'email' });
    }

    store.dispatch('user', login());
  } catch (err) {
    throw err;
  }
}
