import { NEW_ROUTE } from '../actions/route.js';

export interface RouteState {
  view: HTMLElement;
  url: string;
}

const route = {
  view: null,
  url: null,
};
export function routeReducer(state = route, action: any) {
  switch (action.type) {
    case NEW_ROUTE:
      return Object.assign({}, state, action);
    default:
      return state;
  }
}
