export const delay = (ms) => new Promise((res) => setTimeout(() => res(), ms));

/**
 * Waits delay than calls callback
 */
export function debounce(callback, delay = 0) {
  let timeoutRef;

  return function (...args) {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => callback.apply(this, args), delay);
  };
}
/**
 * Runs callback and then waits limit to call next one
 */
 export function throttle(callback, limit) {
  let timeoutRef;
  let lastRan;
  return function (...args) {
    const context = this;

    if (!lastRan) {
      callback.apply(context, args);
      lastRan = Date.now();
    } else {
      const nextRun = limit - (Date.now() - lastRan);
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => {
        lastRan = Date.now();
        callback.apply(context, args);
      }, nextRun);
    }
  };
}
export function cachePromiseFor(callback, refresh = 0) {
  let lastRan = false;
  let response = null;
  return async function (...args) {
    const nextRun = Date.now() - lastRan >= refresh;

    if (!lastRan || nextRun) {
      lastRan = Date.now();
      response = callback.apply(this, args);
    } else {
      console.log('[cachePromiseFor] using cached response');
    }
    const result = await response;
    if (!result) {
      lastRan = false;
    }
    return result;
  };
}

export function consoleCss(category) {
  const colors = {
    error: '#f44336',
    warn: '#ffc107',
    state: 'orange',
    store: '#00bcd4',
    api: '#e91e63',
    sync: 'hsl(122deg 39% 49%)',
  };
  return `background: ${colors[category] || '#3f51b5'}; color: white; padding: 2px 6px; border-radius: 0.3rem;`;
}

export function arrayDiff(arr1, arr2) {
  const uniqueArr1 = new Set(arr1);
  const uniqueArr2 = new Set(arr2);

  return [...arr2.filter((x) => !uniqueArr1.has(x)), ...arr1.filter((x) => !uniqueArr2.has(x))];

  return arr1.filter((x) => !uniqueArr2.has(x));
}

export const isArray = (x) => Array.isArray(x);
export const isString = (x) => typeof x === 'string';

/**
 * Supports Array to Map, Map to Array/Object, Object to Array with or without key
 * @param {(Array|Map|Object)} input
 * @param {(Map|Array|Object)} output
 * @param {string} [key = 'id'] - default is id
 * @returns output
 */
// input, opts = { output: 'Map', key: 'id' }
export function changeType(input, output, key = 'id') {
  if (!output) throw new Error('No output');

  if (isArray(input)) {
    switch (output) {
      case 'Map': {
        const res = new Map();
        for (const item of input) {
          res.set(item[key], item);
        }
        return res;
      }
    }
  } else if (input instanceof Map) {
    if (output === 'Array') return Array.from(input.values());
    else if (output === 'Object') return Object.fromEntries(input);
    else throw new Error('unknown output');
  } else if (isString(input)) {
    switch (output) {
      case 'Array': {
        return [input];
      }
    }
  } else if (typeof input === 'object') {
    if (output === 'Array' && key) return Object.keys(input).map((k) => Object.assign({}, { [key]: k }, input[k]));
    else if (output === 'Array') return Object.values(input);
  } else {
    throw new Error('Unknown input');
  }
}
