import { LitElement, html, css, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import normalize from 'css/normalize';
import { ServerLog } from 'types/server/rota';
import { format } from 'helpers/datetime/mod';

const category = 'rota-tooltip';

const TRANSLATE_TYPE = {
  op: 'OP Dienst',
  nb: 'NB Dienst',
  its: 'ITS Dienst',
  nef: 'NEF',
  f: 'Frei nach Dienst',
}

const txLogType = (type: string) => TRANSLATE_TYPE[type] || type;

@customElement(category)
export class Tooltip extends LitElement {
  @property({ type: Array }) logs: ServerLog[];

  protected render() {
    return this.logs.map(
      (l) => html`
        <section>
          <div class="metadata">${l.upd
            ? format(new Date(l.upd), 'yyyy-MM-dd HH:mm:ss')
            : ''} | ${l.user}
          </div>
          ${this.renderLogEntries(l)}
        </section>
      `
    );
  }
  private renderLogEntries(l: ServerLog) {
    return Object.keys(l)
      .map((key) => this.renderLogType(key, l))
      .filter(Boolean);
  }
  private renderLogType(key: string, l: ServerLog) {
    switch (key) {
      case 'date':
        return html`<div><b>${txLogType(l.type[0])}</b> (verschoben vom ${l.date[0]})</div>`;
      case 'type':
        if (l.type && l.type.length == 2) {
          return html`<div><b>${txLogType(l.type[1])}</b> (geändert von ${txLogType(l.type[0] || 'Verfügbar')})</div>`;
        } else if (l.type === 'f') {
          return html`<div><b>${txLogType(l.type)}</b></div>`;
        }
        return;
      case 'uid':
        return console.error('not implemented uid log');
        // html`<div><b>${txLogType(l.type ? l.type[0] : null)}</b> getauscht mit ${l.uid[0]}</div>`;
      case 'deleted':
        return html`<div><b>Verfügbar</b> (${txLogType(l.deleted)} wurde gelöscht)</div>`;
      case 'new':
        return html`<div><b>${txLogType(l.new)}</b> wurde neu eingeteilt</div>`;
      //case 'note':
        //return html`<div>${l.note}</div>`;
    }
  }
  protected firstUpdated(): void {
    this.animate(
      [
        { opacity: '0', transform: 'scale(0.75)' },
        { opacity: '1', transform: 'scale(1)' },
      ],
      { duration: 300, easing: 'ease-in-out', fill: 'forwards' },
     );

  }
  async fadeOut() {
    const animation = this.animate(
      [
        { opacity: '1', transform: 'scale(1)' },
        { opacity: '0', transform: 'scale(0.75)' },
      ],
      { duration: 300, easing: 'ease-out', fill: 'forwards' },
    )
    await animation.finished;
    this.remove();
  }

  static styles = [
    normalize,
    css`
      :host {
        position: fixed;
        bottom: var(--corner-position, 70px);
        right: var(--corner-position, 70px);

        border: 1px solid hsl(43deg 96% 58%);
        background: #ffffcc !important;

        padding: var(--space-xxs);
        border-radius: var(--space-xxs);

        box-shadow: var(--shadow-6);
        pointer-events: none;

        white-space: nowrap;
        padding: 0;
        z-index: 2;
        color: black;
        text-align: left;
        font-size: 0.7rem;
        font-weight: normal;

        opacity: 0;
      }
      section {
        padding: 5px;
      }
      .metadata {
        opacity: 0.5;
        line-height: 1.3;
      }
      @keyframes fade-in {
        from {
          opacity: 0;
          transform: scale(0.75);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: Tooltip;
  }
}