import { Base } from 'types/generic';
import { ADD_USERS } from '../actions/users';

export interface LeaveValues {
  age: number;
  anniversary: number;
  rest: number;
  night: number;
  allowed: number;
  total?: number; // only for summary, is not stored
}
type Year = number;
export type Hospital = 'DKH' | 'DKF' | '---';

export type AbsenceCategory = 'DKH' | 'DKF' | '---' | 'HOS' | 'EZ';

export interface Absence {
  id: string;
  type: AbsenceCategory;
  start: string;
  end: string;
}

export interface ServerUser {
  base: Base;
  first_name: string;
  hospital: Record<Year, Hospital[]>;
  absence?: Absence[];
  id: string;
  level: string;
  name: string;
  oncalls: string[];
  part_time: string[];
  leave?: Record<Year, LeaveValues>;
  role: string;
  upd: number;
}
export interface UserState extends ServerUser {
  displayName: string;
  hhd: boolean;
}

const users: UserState[] = [];

export function usersReducer(state = users, action): UserState[] {
  switch (action.type) {
    case ADD_USERS:
      return action.users;
    default:
      return state;
  }
}
