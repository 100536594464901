import * as dom from './dom.js';

export default async function dialog(title, opts = {}) {
  await import(/* webpackChunkName: "app-dialog" */ '../components/app-dialog.js').catch(_ => console.error(_));
  const {
    className,
    icon = '',
    id,
    header = '',
    message,
    warning,
    layout = [],
    settings = {},
    value,
    options = {},
    btns = [{ label: 'Abbrechen', action: 'close' }, { label: 'speichern', action: 'submit' }],
    callback,
    parent = document.body,
  } = opts;

  return dom.create('app-dialog-old', {
    className,
    id,
    attrs: { icon, title, header },
    props: { layout, btns, settings, value, callback, message, warning, options },
    parent,
  });
}
