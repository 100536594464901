/**
 * v1
 */
import beacon from 'helpers/beacon';
import { _fetch } from 'helpers/fetch';
import idb from 'logic/idb';
import timely from 'logic/timely';

const FETCH_URL = location.origin + '/api/v2/logs/';

const cssLogs = (category) => {
  const colors = {
    error: '#f44336',
    warn: '#ffc107',
  };
  return `background: ${colors[category] || '#3f51b5'}; color: white; padding: 2px 6px; border-radius: 0.3rem;`;
};

export function elog(action, opts = {}) {
  console.assert(action, 'no action provided');
  let { category = 'error', label = null, value = null } = opts;

  if (label && typeof label === 'object') label = JSON.stringify(label);

  const timestamp = new Date().getTime();
  const date = timely().timeZone('UTC').format();

  idb.add([{ action, category, label, date, value, timestamp }], 'logs');
  console.log('%c%s', cssLogs(category), category, action, label || '', value || '');
  return { action, category, label, date, value };
}

let inProgress;
export async function postLogs() {
  if (inProgress) return console.warn('postLogs in progress');
  inProgress = true;

  const [logs] = await Promise.all([idb.getAll('logs')]);
  if (!logs.length) return (inProgress = false);

  /*
  const body = {
    logs: JSON.stringify(logs),
  };
  const res = await _fetch(FETCH_URL, { body, method: 'POST' }).catch((err) => console.error(err));
  if (res && res.success) {
    const tasks = logs.map((log) => idb.delete('logs', log.id));
    await Promise.all(tasks).catch((err) => beacon(err, { label: 'postLogs failed to delete' }));
  }
  */

  const tasks = logs.map((log) => idb.delete('logs', log.id));
  await Promise.all(tasks);

  inProgress = false;
}
