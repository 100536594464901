import idb from 'logic/idb';
import { FileState } from 'state/reducers/file';

const supportedTypes = {
  plan: [{
    description: 'Arbeitsplan',
    accept: {
      'text/json': ['.plan']
    },
  }],
  log: [{
    description: 'Log Datei',
    accept: {
      'text/json': ['.log']
    },
  }],
  eml: [{
    description: 'Email Datei',
    accept: {
      'plain/text': ['.eml']
    },
  }],
  csv: [{
    description: 'CSV Datei',
    accept: {
      'text/csv': ['.csv']
    },
  }],
  pem: [{
    description: 'Kryptografischer Schlüssel',
    accept: {
      'text/plain': ['.pem']
    },
  }],
};

// readwrite
export async function hasPermission(handle: FileSystemHandle, mode: FileSystemPermissionMode = 'read') {
  if ((await handle.queryPermission({ mode })) === 'granted') {
    console.log('permission already granted');
    return true;
  } else if ((await handle.requestPermission({ mode })) === 'granted') {
    return true;
  } else {
    return false; // user didn't grant permission
  }
}

export async function saveFile(suggestedName: string, fileExt: string, contents: string|Blob, _handle = null) {
  const types = supportedTypes[fileExt];

  const handle = _handle
    ? _handle
    : await window.showSaveFilePicker({ suggestedName, types });

  const writable = await handle.createWritable();
  await writable.write(contents);
  await writable.close();
  return handle;
}

async function saveHandleToIndexedDB(file: File, handle: FileSystemHandle) {
  const savedHandles = await idb.getAll('handles');
  const alreadySaved = savedHandles.filter(
    (saved) => saved.name == file.name && saved.size == file.size && saved.lastModified == file.lastModified
  );
  if (alreadySaved.length === 0) {
    if (savedHandles.length > 4) {
      console.error('more than 5 recent files', savedHandles);
      const oldestEntry = savedHandles[0];
      await idb.delete('handles', oldestEntry.id);
      console.warn('deleted file handle', oldestEntry);
    }
    await idb.add(
      [{ id: Date.now(), name: file.name, size: file.size, lastModified: file.lastModified, handle: handle }],
      'handles'
    );
  } else {
    console.log('updating recent files key', alreadySaved);
    await idb.delete('handles', alreadySaved[0].id);
    const updatedHandle = Object.assign({}, alreadySaved[0], { id: Date.now() });
    await idb.add([updatedHandle], 'handles');
  }
}

// no cancel in event - https://github.com/whatwg/html/issues/6376
function legacyFileOpen(): Promise<File> {
  return new Promise((res, rej) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.plan';
    input.addEventListener(
      'change',
      () => res(input.files[0]),
      { once: true }
    );
    input.click();
  });
}
async function openFileDialog() {
  const [handle] = await window.showOpenFilePicker({
    types: supportedTypes.plan,
    //multiple: true,
  });

  const file = await handle.getFile();
  saveHandleToIndexedDB(file, handle);
  return file;
}
export async function openFile() {
  const file = 'showOpenFilePicker' in self ? await openFileDialog() : await legacyFileOpen();
  const content = await file.text();
  const results = JSON.parse(content);

  results.name = file.name;
  results.lastModified = file.lastModified;
  //results.handle = handle;

  console.log(results);
  return results;
}
export async function openMultipleFiles(fileExt: string, contents = []) {
  let data = [...contents];
  const types = supportedTypes[fileExt];
  const handles = await window.showOpenFilePicker({
    types,
    multiple: true,
  });

  for (const handle of handles) {
    const file = await handle.getFile();
    const content = await file.text();
    data = [...data, ...JSON.parse(content)];
  }

  return data;
}
export async function openCryptoKeys() {
  const res = new Map();
  const types = supportedTypes.pem;
  const handles = await window.showOpenFilePicker({ types, multiple: true });
  for (const handle of handles) {
    const file = await handle.getFile();
    const content = await file.text();
    res.set(file, content);
  }
  return res;
}
export async function openRecentFile(handle: FileSystemFileHandle) {
  const file = await handle.getFile();
  const text = await file.text();
  return JSON.parse(text);
}
export async function recentFiles() {
  const files = await idb.getAll('handles');
  return files.reverse();
}

export async function saveSheet(title: string, backup: unknown) {
  saveFile(title, 'plan', JSON.stringify(backup));
}
export async function save(title: string, backup: unknown, handle: FileSystemFileHandle, file: FileState) {
  console.log('last saved: ', file.lastModified);

  const recent = await handle.getFile();
  console.log('recent saved:', recent.lastModified);

  if (file.lastModified !== recent.lastModified) {
    return console.error('FILE MODIFIED');
  }


  saveFile(title, 'plan', JSON.stringify(backup), handle);
}

export async function saveToDirectory(dirHandle: FileSystemDirectoryHandle, fileName: string, content: string) {
  const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
  const writable2 = await fileHandle.createWritable();
  await writable2.write(content);
  await writable2.close();
}