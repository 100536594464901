import swURL from 'service-worker:../../sw.ts';

const TOAST_ELEMENT = 'app-notify';
const UPDATE_MESSAGE = 'Neues Update verfügbar';

export function updateReady() {
  const notify = document.body.querySelector(TOAST_ELEMENT);
  if (notify) notify.new(UPDATE_MESSAGE, { callback: activatePendingServiceWorker });
}

function onControllerChange() {
  return new Promise<void>((resolve) => {
    navigator.serviceWorker.addEventListener(
      'controllerchange',
      () => resolve(),
      { once: true },
    );
  });
}
function onStateChange(e: Event) {
  const sw = e.target as ServiceWorker;
  if (sw.state === 'installed') updateReady();
  console.log('sw/state-change', sw.state);
}
function onUpdateFound(e: Event): void {
  console.log('sw/update-found');
  const reg = e.target as ServiceWorkerRegistration;
  reg.installing.addEventListener('statechange', onStateChange);
}

export async function registerServiceWorker() {
  if (!navigator.serviceWorker) return;

  const reg = await navigator.serviceWorker.register(swURL).catch((err) => console.error(err));

  let hadPreviousController = !!navigator.serviceWorker.controller;

  while (true) {
    if (reg && reg.active) {
      reg.addEventListener('updatefound', onUpdateFound);
    }

    await onControllerChange();

    // Don't reload for the first controller (eg initial sw registration).
    if (hadPreviousController) {
      location.reload();
      return;
    }

    hadPreviousController = true;
  }
}

export async function checkForServiceWorkerUpdate() {
  if (!navigator.serviceWorker) return;
  const reg = await navigator.serviceWorker.getRegistration().catch((err) => console.log(err));
  if (reg) reg.update();
}

export async function serviceWorkerUpdatePending(): Promise<boolean> {
  if (!navigator.serviceWorker) return false;

  const reg = await navigator.serviceWorker.getRegistration().catch((err) => console.log(err));
  if (!reg) return false;

  return !!reg.waiting;
}

export async function activatePendingServiceWorker(): Promise<void> {
  if (!navigator.serviceWorker) return;

  const reg = await navigator.serviceWorker.getRegistration();
  if (!reg || !reg.waiting) throw Error('No pending service worker');

  console.warn('sw/activatePendingServiceWorker');

  reg.waiting.postMessage({ action: 'skipWaiting' });
}

export async function killServiceWorkers() {
  if (!navigator.serviceWorker) return;
  const regs = await navigator.serviceWorker.getRegistrations().catch((err) => console.log(err));
  if (regs) {
    const promises = regs.map((reg) => reg.unregister());
    await Promise.all(promises);
    location.reload();
  }
}
