function convertArrayBufferIntoString(buf: ArrayBuffer) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}
function convertStringIntoArrayBuffer(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// Generate encryption key pair
export async function generateKeyPair() {
  const keyPair = await window.crypto.subtle.generateKey({ name: 'ECDH', namedCurve: 'P-256' }, true, [
    'deriveKey',
    'deriveBits',
  ]);
  // PrivateKey: encrypt, sign
  const privateKey = await exportPrivateKey(keyPair.privateKey);

  // PublicKey: decrypt, verify
  const publicKey = await exportPublicKey(keyPair.publicKey);

  return { privateKey, publicKey };
}
async function exportPrivateKey(key: CryptoKey) {
  // pkcs8 for private key
  const exported = await crypto.subtle.exportKey('pkcs8', key);
  const exportedAsString = convertArrayBufferIntoString(exported);
  const exportedAsBase64 = window.btoa(exportedAsString);
  return `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----`;
}
async function exportPublicKey(key: CryptoKey) {
  // spki for public key
  const exported = await crypto.subtle.exportKey('spki', key);
  const exportedAsString = convertArrayBufferIntoString(exported);
  const exportedAsBase64 = window.btoa(exportedAsString);
  return `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
}

// Import keys
export function importKey(pem: string, type: string) {
  // fetch PEM string between header and footer
  const pemHeader = `-----BEGIN ${type} KEY-----`;
  const pemFooter = `-----END ${type} KEY-----`;
  const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);

  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents);
  // convert from a binary string to an ArrayBuffer
  const binaryDer = convertStringIntoArrayBuffer(binaryDerString);

  const alg = { name: 'ECDH', namedCurve: 'P-256' };

  if (type === 'PRIVATE') {
    return crypto.subtle.importKey('pkcs8', binaryDer, alg, true, ['deriveKey', 'deriveBits']);
  } else if (type === 'PUBLIC') {
    return crypto.subtle.importKey('spki', binaryDer, alg, true, []);
  } else {
    throw new Error('Invalid type');
  }
}

/**
 * Encrypts plaintext using AES-GCM with derived key
 *
 * @param   {String} plaintext - Plaintext to be encrypted.
 * @param   {String} derivedKey - Password to use to encrypt plaintext.
 * @returns {String} Encrypted ciphertext.
 */
export async function aesGcmEncrypt(plaintext: string, derivedKey: CryptoKey) {
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const alg = { name: 'AES-GCM', iv };

  const ptUint8 = new TextEncoder().encode(plaintext);
  const ctBuffer = await crypto.subtle.encrypt(alg, derivedKey, ptUint8);
  const ctBase64 = window.btoa(convertArrayBufferIntoString(ctBuffer)); // encode ciphertext as base64

  // Convert iv typedArray into hex string
  // map converts each byte to a hex string (e.g. 12 becomes c)
  // then takes that hex string and left pads it with zeros (e.g., c becomes 0c)
  // then joins array / hex values to string
  const ivHex = Array.from(iv)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return ivHex + ctBase64;
}

/**
 * Decrypts ciphertext encrypted with aesGcmEncrypt() using derived key
 * @param   {String} ciphertext - Ciphertext to be decrypted.
 * @param   {String} derivedKey - Password to use to decrypt ciphertext.
 * @returns {String} Decrypted plaintext.
 */
export async function aesGcmDecrypt(ciphertext: string, derivedKey: CryptoKey) {
  const iv = ciphertext
    .slice(0, 24)
    .match(/.{2}/g)
    .map((byte) => parseInt(byte, 16)); // get iv from ciphertext

  const alg = { name: 'AES-GCM', iv: new Uint8Array(iv) };

  const ctStr = window.atob(ciphertext.slice(24)); // decode base64 ciphertext
  const ctUint8 = convertStringIntoArrayBuffer(ctStr);

  const plainBuffer = await crypto.subtle.decrypt(alg, derivedKey, ctUint8);
  const plaintext = new TextDecoder().decode(plainBuffer);
  return plaintext;
}
