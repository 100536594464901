export const routeEvent = 'locationchange';

export const CurrentRoute = (baseElement) =>
  class extends baseElement {
    constructor() {
      super();
      this._onLocationChange = this._onLocationChange.bind(this);
    }
    connectedCallback() {
      if (super.connectedCallback) {
        super.connectedCallback();
      }
      window.addEventListener(routeEvent, this._onLocationChange);
    }
    disconnectedCallback() {
      if (super.disconnectedCallback) {
        super.disconnectedCallback();
      }
      window.removeEventListener(routeEvent, this._onLocationChange);
    }
    _onLocationChange(e) {
      const view = document.body.querySelector('.visible');
      this.routeChanged(view);
    }
    /**
     * Called when route is updated.
     */
    routeChanged(view) {}
  };
