import { LitElement, html, css } from 'lit';
import { customEvent } from '../helpers/events';

const category = 'app-button';

class AppButton extends LitElement {
  static get properties() {
    return {
      disabled: { type: Boolean },
      href: { type: String },
      icon: { type: String },
      size: { type: String },
      name: { type: String },
      action: { type: String },
    };
  }

  constructor() {
    super();
    this.disabled = false;
    this.href = '';
    this.icon = '';
    this.size = '24';
    this.name = '';
    this.action = '';
  }
  connectedCallback() {
    super.connectedCallback();
  }
  _onClick() {
    if (!this.action) return;
    this.dispatchEvent(customEvent('action', this.action));
  }
  render() {
    return html`
      <button type="button" @click="${this._onClick}">
        <span class="icon">${this.icon ? html`<svg-icon size="${this.size}">${this.icon}</svg-icon>` : ''}</span>
        <span class="text"><slot></slot></span>
        <span></span>
      </button>
    `;
  }

  static get styles() {
    return [
      css`
        :host {
          --text-decoration: none;
          --text-transform: capitalize;
          --line-height: 1;
          --gap: 1rem;
          --columns: 0fr 1fr 0fr;
          --radius: 0.25rem;
          display: flex;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0.00714em;
        }
        :host([circle]) {
          --gap: 0rem;
          --columns: repeat(3, 0fr);
          --radius: 100%;
        }
        :host([disabled]) {
          opacity: 0.3;
          pointer-events: none;
        }
        button {
          display: grid;
          grid-template-columns: var(--columns);
          gap: var(--gap);
          text-align: inherit;
        }
        button {
          position: relative;
          color: inherit;
          width: 100%;
          align-items: center;
          border-radius: var(--radius);
          padding: var(--space-xs);
          overflow: hidden;
          font-family: inherit;
          font-size: inherit;
          letter-spacing: inherit;
          font-weight: inherit;
          line-height: var(--line-height);
          border: 0;
          cursor: pointer;
          background: transparent;
          -webkit-tap-highlight-color: transparent;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
        }
        button:active {
          background: none;
        }
        button::after,
        button::before {
          position: absolute;
          top: calc(50% - 100%);
          left: calc(50% - 100%);
          background: hsl(0deg 0% var(--brightness));
          width: 200%;
          height: 200%;
          border-radius: 50%;
          opacity: 0;
          pointer-events: none;
          content: '';
        }
        button::before {
          transition: opacity 15ms linear, background-color 15ms linear;
          z-index: 1;
        }
        button::after {
          transition: opacity 150ms linear;
        }
        button:focus {
          outline: 0;
        }
        button:hover::before {
          opacity: 0.04;
        }
        button:active::after {
          transition-duration: 75ms;
          opacity: 0.12;
        }
        span.text::first-letter {
          text-decoration: var(--text-decoration);
        }
        svg-icon {
          color: inherit;
        }
        button * {
          pointer-events: none;
        }
        span.text {
          text-transform: var(--text-transform);
        }
      `,
    ];
  }
}
customElements.define(category, AppButton);
