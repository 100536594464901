import { consoleCss } from 'helpers/utils';
import { signin } from 'helpers/components';
import { validateToken } from 'state/actions/user';

const API_VERSION = 'v2';

const enum ContentType {
  JSON = 'application/json; charset=UTF-8',
}
function result(res: Response) {
  const contentType = res.headers.get('content-type');
  switch (contentType) {
    case ContentType.JSON:
      return res.json();
    default:
      return res.text();
  }
}
function isOffline() {
  try {
    if (!navigator.onLine) throw new Error('You are offline.');
  } catch (err) {
    err.code = 503; // network unavailable
    return err;
  }
  return false;
}
async function handleAuth(service: string) {
  if (service === 'auth/login') return {};

  const accessToken = sessionStorage.getItem('accessToken');
  const refreshToken = sessionStorage.getItem('refreshToken');

  try {
    if (!accessToken || !refreshToken) {
      throw new Error('auth/no-tokens: Sitzung abgelaufen.');
    } else if (validateToken(refreshToken) === false) {
      throw new Error('auth/expired-refresh-token: Sitzung abgelaufen.');
    } else if (validateToken(accessToken) === false) {
      if (service === 'auth/refresh') return { accessToken, refreshToken };

      // try refresh!
      throw new Error('auth/expired-access-token: Sitzung abgelaufen.');
    }

    return { accessToken, refreshToken };
  } catch (err) {
    console.error(err);
    await signin.showLogin(err.message);
    return handleAuth(service);
  }
}

type ExtendedRequestInit = RequestInit & {
  headers: {
    Authorization?: string;
    Refreshtoken?: string;
  }
}
async function createRequestOptions(method: string, service: string, options: FetchOptions = {}) {
  let { body, auth = true } = options;

  const request: ExtendedRequestInit = {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
  };

  if (auth) {
    const { refreshToken, accessToken } = await handleAuth(service);
    request.headers.Authorization = `Bearer ${accessToken || ''}`;
    request.headers.Refreshtoken = refreshToken || '';
    request.headers['X-Requested-With'] = 'XMLHttpRequest';
  }
  if (body) {
    request.body = body instanceof FormData ? body : JSON.stringify(body);
    request.headers['X-Requested-With'] = 'XMLHttpRequest';
  }
  if (method !== 'get') {
    request.method = method.toUpperCase();
  }
  return request;
}

interface FetchOptions {
  body?: FormData | unknown;
  auth?: boolean;
}
export default async function api(method: 'get' | 'post' | 'put' | 'delete', col: string, path = '', options: FetchOptions = {}) {
  if (isOffline()) throw new Error('api/offline');

  if (col === 'leaveAllocation') col = 'leave';

  const request = await createRequestOptions(method, `${col}/${path}`, options);
  const response = await fetch(`/api/${API_VERSION}/${col}/${path}`, request);
  const status = response.status;
  const headers = response.headers;
  const res = await result(response);

  //console.log(headers.get('Token'));

  if (status >= 200 && status < 300) {
    console.log('%c%s', consoleCss('api'), 'api', method, col, path, res);
    return res;
  } else if (status === 401) {
    console.log(res);
    signin.showLogin();
    throw new Error('401');
  } else {
    throw new Error('Unknown error: ' + status);
  }
}
