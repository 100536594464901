import { LitElement } from 'lit';
import { state } from 'lit/decorators.js';
import * as dom from 'helpers/dom';
import { sortByName } from 'helpers/sort';

import * as dialogs from 'logic/rota/dialogs.js';
import { openFile, saveFile } from 'logic/pwa/file-system.js';
import timely from 'logic/timely';

import { leave as listLeaveType, rota as listRotaType, userEdit } from '../config/rota';

import idb from 'logic/idb';
import { TemplateInterface } from './template-mixin.js';
import { Collection, RotaViewChecker } from 'config/rota-view';
import { SyncController } from 'controller/sync';
import sync from 'logic/sync';
import { StateController } from 'controller/state';
import { viewFile, closeFile } from 'state/actions/file';
import { CursorController } from './controllers/cursor';
import { DialogController } from 'controller/dialog';
import { format, parse } from 'helpers/datetime/mod';
import { getBz } from 'logic/calendar';
import { RotaController } from 'controller/rotaController';
import { UserState } from 'state/reducers/users';
import { ProfileState } from 'state/reducers/user';
import { Base } from 'types/generic';
import { DragController } from './controllers/drag';
import { Column, Gesture, UpdateCell } from 'types/sheet/update';

type Constructor<T = {}> = new (...args: any[]) => T;

export declare class HandlerInterface extends TemplateInterface {
  protected _resetState(): unknown;
  protected _onFocusInSummary(e: Event): void;
  protected _onFocusOutSummary(e: Event): Promise<void>;
  protected _onClickHeader(e: Event): void;
  protected _onClickCaption(e: Event): Promise<void>;
  protected _onClickBody(e: Event): void;
  protected _onRightClick(e: Event): void;
  availability: Availability;
  selected: Selected[];
}

/**
 * TYPE: string - deprecate; string[] with currently max 2; undefined if never set; null if was deleted
 */
interface Selected {
  index: number;
  date: string;
  type: string | string[] | undefined | null;
  uid: string;
}
interface DomData {
  index: number;
  uid: string;
  type: string | string[];
  date: string;
}
export interface Availability {
  unavailable: string[],
  available: string[],
  availableNextDay: string[],
}

export class RotaUpdateEvent extends Event {
  static eventName = 'rotaUpdate';
  gesture: Gesture;
  col: Column;
  tasks: UpdateCell[];

  constructor(gesture: Gesture, col: Column, tasks: UpdateCell[]) {
    super(RotaUpdateEvent.eventName, { bubbles: true, composed: true });
    this.gesture = gesture;
    this.col = col;
    this.tasks = tasks;
  }
}

export const Handler = <T extends Constructor<LitElement>>(superClass: T) => {
  class HandlerElement extends superClass implements RotaViewChecker {
    // host
    dialog: DialogController;
    sync: SyncController;
    drag: DragController;
    state: StateController;
    rota: RotaController;
    cursor: CursorController;
    readonly hasOnCallColumn: boolean;
    readonly isLeave: boolean;

    collection: Collection;
    base: Base;
    isVisible: boolean;

    recentChanges: Set<string>;
    _preview: boolean;
    _auth: ProfileState;
    _role: string | null;
    _userList: UserState[];
    // host end

    @state() _bc: BroadcastChannel;
    @state() selected: Selected[];
    @state() availability: Availability = { unavailable: [], available: [], availableNextDay: [] };

    @state() _highlightRow;
    @state() _highlightUserColumn: string; //uid

    @state() summaryIsFocused: boolean;
    @state() summaryElement: HTMLInputElement;
    @state() summaryInitialValue: string;

    _debounceArrowKeys: (key: string, selectedCell: Selected) => void;

    _updateView: () => Promise<void>;
    _updateArrowButtons?: () => void;

    date: string;
    //noLogsUntil: string;
    //noLogs: false | string[];
    _server: null | [];
    _currentDate: string;
    _disableEdit: boolean;
    _filter: string | null;

    year?: number;
    bz?: number[];
    _displayMonth? : number;
    _pendingUserSync?: boolean; // used for ulruabsplanung-views only

    _showAllRows: boolean; // leave year

    constructor(...args: any[]) {
      super(...args);

      //this.selected = [];

      this._highlightRow = null;

      this._onDialogSubmit = this._onDialogSubmit.bind(this);
      this._onClickBody = this._onClickBody.bind(this);

      //@ts-ignore
      this.addEventListener('dialog', this._onDialogSubmit);
      //@ts-ignore
      this.addEventListener('menu-select', this._onSelectContextMenu);
      this.addEventListener('rotadelete', this._onRotaDelete);
      this.addEventListener('rotaUpdate', this._onRotaUpdate);
    }
    connectedCallback() {
      super.connectedCallback();
      this._bc = new BroadcastChannel('refresh');
      this._bc.onmessage = (e) => {
        //console.log('BROADCAST', e);
        if (e.data === 'refresh' && this.isVisible) {
          console.log('broadcast refreshing page');
          this._updateView();
        }
      };
      //window.addEventListener('keydown', this._onKeyDown);
    }
    disconnectedCallback() {
      super.disconnectedCallback();
      this._bc.close();

      //window.removeEventListener('keydown', this._onKeyDown);
    }

    /**
     * Reset highlight rows
     * Locked set to false ?? what does locked do?
     */
    _resetState() {
      this._resetHighlightRow();
      //this._deselectCells();
      this._preview = false;
    }
    _deselectCells() {
      this.cursor.selections.clear();
      const selectedCells = this.shadowRoot.querySelectorAll('.selected');
      selectedCells.forEach((el) => el.classList.remove('selected'));
    }
    _resetHighlightRow() {
      if (this._highlightRow) {
        this._highlightRow = null;
        this.availability = { unavailable: [], available: [], availableNextDay: [] }
      }
    }

    async _showUserAvailability(date: string) {
      if (this._highlightRow === date) {
        this._resetHighlightRow();
      } else {
        this.availability = await this.rota.getAvailableUsers(date);
        this._highlightRow = date;
      }
    }
    _showCustomSollDialog(date: string, value: number) {
      //this._deselectCells();
      return dialogs.customSoll(value, date, this);
    }
    _showNotesDialog(el: HTMLElement, date: string, highlight: boolean) {
      //this._deselectCells();
      const { value } = el.querySelector('input');
      return dialogs.notes(value, date, highlight, this);
    }
    async _onClickBody(e: Event) {
      if (this._disableEdit) return console.error('No rota created yet');

      const el = e.target as HTMLElement;
      const tr = el.closest('[data-index]');
      const td = el.closest('[data-col]');

      //console.log(tr, td);
      if (!td || !tr) return;
      //console.log(tr, td);

      const data: Record<string, unknown> = dom.getDataFromElements([tr, td]);


      switch (data.col) {
        case 'date':
          if (this.isLeave) return;
          else if (this._role === 'Benutzer') return console.warn('No permission');
          return await this._showUserAvailability(data.date);
        case 'user':
          return await this.cursor.selectUserCell(e);
        case 'required':
          if (this._role === 'Benutzer') return console.warn('No permission');
          return this._showCustomSollDialog(data.date, +el.textContent);
        case 'notes':
          if (this._role === 'Benutzer') return console.warn('No permission');
          const isHighlight = el.classList.contains('highlight');
          return this._showNotesDialog(el, data.date, isHighlight);
        default:
          return;
      }
    }

    // actionController
    _resetSheet() {
      this._resetState();
      this._server = null;
      const scrollableEl = document.body.querySelector('app-state');
      scrollableEl.scrollTo({ top: 0 });
      return this._updateView();
    }
    async _changeRotaView(value: string, btn?: HTMLButtonElement) {
      const scrollableEl = document.body.querySelector('app-state');
      scrollableEl.scrollTo({ top: 0 });
      this._resetState();

      if (btn && this.collection !== 'leave') {
        btn.disabled = true;
      }

      let newDate: Date;
      if (value === 'next' || value === 'prev') {
        newDate = value === 'next'
          ? timely(this.date).add(28).instance()
          : timely(this.date).subtract(28).instance();
      } else {
        newDate = parse(value, 'yyyy-MM-dd');
      }

      this.date = format(newDate, 'yyyy-MM-dd');
      this.bz = getBz(newDate);
      this.year = newDate.getFullYear();

      await this.rota.reload();
    }
    async _changeLeaveYear(value: string) {
      const scrollableEl = document.body.querySelector('app-state');
      scrollableEl.scrollTo({ top: 0 });
      this.rota.table.body = [];

      this.date = value;
      this.year = timely(value).year();

      this._resetState();
      this.rota.reload();
    }
    /**
     * month - must be JavaScript month
     */
    async _scrollToMonth(month: number) {
      this._showAllRows = true;
      await this.updateComplete;

      // js date to normal date
      const m = (+month + 1).toString();
      //this._displayMonth = m;
      const id = this.shadowRoot.getElementById(`${this.year}-${m.padStart(2, '0')}-01`);
      id.scrollIntoView();
    }
    async _changeCellBackground() {
      if (this.cursor.focusedCell === null) return alert('Zelle muss markiert sein!');
      else if (this.cursor.selections.size > 1) return alert('Es darf nur eine Zelle markiert sein!');

      const { date, uid } = this.cursor.focusedCell;
      await this.rota.toggleHighlight(date, uid);
    }
    async _deleteSheet(el: HTMLButtonElement) {
      el.disabled = true;
      const res = await dialogs.confirm('Diesen Entwurf löschen?').catch((_) => false);
      if (res) {
        await this.rota.deleteSheet();
        await this.rota.reload();
      }
      el.disabled = false;
    }
    async _publishDraftSheet(btn: HTMLButtonElement) {
      btn.disabled = true;
      const res = await dialogs.confirm('Diesen Entwurf veröffentlichen?').catch((_) => false);
      if (res) {
        const [year, month] = await this.rota.publishTemplate();
        await sync();

        const navButton = this.shadowRoot.querySelector('.nav-month[disabled]');
        navButton.remove();

        this._changeRotaView('next');
      }
      btn.disabled = false;
    }
    async _openSheet(btn: HTMLButtonElement) {
      btn.disabled = true;
      this._resetState();
      try {
        const file = await openFile();
        file.rows = new Map(file.rows);
        this.state.dispatch('file', viewFile(file));
      } catch (err) {
        console.error(err);
      }
      btn.disabled = false;

    }
    async _closeSheet() {
      this.state.dispatch('app', closeFile());
    }
    _toggleFullscreen() {
      /*
      if ('getScreens' in window) {
        const granted = await hasPermission('window-placement');
        //if (!granted) return;
        if (!window.screen.isExtended) return false;

        console.log(granted, window.screen.isExtended);
        const infoScreens = await window.getScreens();
        console.log(infoScreens);
        try {
          const [secondaryScreen] = infoScreens.screens.filter((screen) => !screen.isPrimary);
          const leaveEl = $('app-view[name="Urlaub"]');
          leaveEl.classList.add('visible');
          await leaveEl.requestFullscreen({ screen: secondaryScreen });
        } catch (err) {
          console.error(err.name, err.message);
        }
      }
      */
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
    async _onClickCaption({ target }) {
      const { action, value } = target.dataset;
      switch (action) {
        case 'changeRotaView':
        case 'changeRotaViewTo':
          return this._changeRotaView(value, target);
        case 'changeLeaveYear':
          return this._changeLeaveYear(value);
        case 'scrollToMonth':
          return this._scrollToMonth(value);
        case 'delete':
          return this._deleteSheet(target);
        case 'csv':
          return await this.rota.exportCsv();
        case 'save':
          const backup = await this.rota.saveBackup();
          return saveFile(`Backup ${this.collection} ${format(new Date(), 'yyyy-MM-dd')}`, 'plan', backup);
        case 'share':
          return await this.rota.shareViaEmail();
        case 'fullscreen':
          return this._toggleFullscreen();
        case 'filter':
          return dialogs.filter(this._filter, this);
        case 'highlight':
          return this._changeCellBackground();
        case 'publish':
          return this._publishDraftSheet(target);
        case 'print':
          return window.print();
        case 'open':
          return this._openSheet(target);
        case 'close':
          return this._closeSheet();
      }
    }

    // context-menu-controller
    async _onRightClick(e) {
      if (e.ctrlKey) return;
      e.preventDefault();
      console.clear();
      const pos = [e.clientX, e.clientY];

      const tr = e.target.closest('[data-index]');
      const td = e.target.closest('[data-col]');
      const data = dom.getDataFromElements([tr, td, e.target]);

      const { col, uid, date, index } = data;

      const { type, _count, note } = await this.rota.getUserRotaEntry(date, uid) || {};

      const isEmptyField = !type || type.length === 0;

      const value = { col, note, date, uid, type, _count, origins: undefined };

      const hasSelectedMultipleCells = this.cursor.selections.size > 1;

      const disable = {
        select: col === 'oncall' || hasSelectedMultipleCells,
        delete: isEmptyField, // || hasSelectedMultipleCells,
        count: isEmptyField || hasSelectedMultipleCells,
        note: hasSelectedMultipleCells || (col === 'oncall' && isEmptyField),
        logs: undefined,
      };

      let list = this.isLeave ? listLeaveType : listRotaType;

      if (this._role === 'Benutzer') {
        list = userEdit;
        disable.select = true;
        disable.count = true;
        disable.logs = true;
      }

      if (col === 'oncall') {
        value.type = data.type;

        list = [];
        if (isEmptyField) {
          //const usersLocum = this._rota.getLocums();
          //list = usersLocum.map((u) => ({ key: u.name, value: u.id }));
          disable.select = false;
          value.origins = [];
        } else {
          value.origins = [{ date, uid, type }];
        }
      } else if (col === 'user') {
        if (hasSelectedMultipleCells) {
          value.origins = Array.from(this.cursor.selections.values());
        } else {
          this.cursor.selections.clear();
          const key = `${date}x${uid}`;
          this.cursor.selections.set(key, { date, uid, type, _count });
          value.origins = Array.from(this.cursor.selections.values());
        }
      }
      console.log('onRightClick', value, disable);

      if (this.querySelector('context-menu')) dom.remove('context-menu', { parent: this });
      dom.create('context-menu', { parent: this, props: { pos, value, list, disable, collection: this.collection } });
    }

    _selectUserColumn(uid: string) {
      this._highlightUserColumn = uid;
      const cells = this.shadowRoot.querySelectorAll(`[data-uid="${uid}"]`);
      cells.forEach((cell) => cell.classList.add('highlightUserColumn'));
    }
    _deselectUserColumn(uid: string) {
      const cells = this.shadowRoot.querySelectorAll(`[data-uid="${uid}"]`);
      cells.forEach((cell) => cell.classList.remove('highlightUserColumn'));
    }
    _onClickHeader({ target }) {
      const { uid } = target.dataset;

      if (this._highlightUserColumn) {
        this._deselectUserColumn(this._highlightUserColumn);
        if (this._highlightUserColumn !== uid) this._selectUserColumn(uid);
        else this._highlightUserColumn = null;
      } else {
        this._selectUserColumn(uid);
      }
    }

    async _contextMenuUpdateCell(origins: DomData[], target: DomData) {
      console.log(origins);

      const tasks = [];
      for (const origin of origins) {
        tasks.push({
          update: {
            uid: origin.uid,
            date: origin.date,
            type: target.type,
          }
        });
      }

      const gesture = this.cursor.selections.size > 0 ? 'multipleUpdates' : 'contextMenu';

      this.dispatchEvent(new RotaUpdateEvent(gesture, 'user', tasks));

      /*
      if (origins.length > 1) {
        await this.rota.updateMultipleUserRota(origins, target, this._auth);
        await this._updateView();
        this._bc.postMessage('refresh');
        $('app-notify').new('Success');
        return;
      }
      const [originEl] = origins;
      const origin = await this.rota.getCellContent(originEl.date, originEl.uid);
      const update = handleContextMenu(origin, target);
      return this._handleUpdateUserRota(update);
      */
    }

    // dialog-controller
    /**
     * To Do: Change power edit in entwurf/dienstplan to Auswahlen!!
     * todo: must use day and month for BZ with two months!!
     */
    async _showDialog(col: string, target: DomData, user: UserState) {
      if (col === 'oncall') {
        const usersLocum = await this.rota.getLocums();
        const list = usersLocum.map((u) => ({ key: `${u.name}, ${u.first_name[0]}`, value: u.id }));
        const sorted = sortByName(list, 'key');
        console.log(sorted);
        return dialogs.locums(sorted, target.type, target.date, this);
      } else {
        return dialogs.allocation(user, target.type, target.date, this);
      }
    }
    async _deleteCell(origins: DomData[], user: UserState) {
      const [origin] = origins;
      const days = origins.length;
      const res = await dialogs
        .confirm(`${origin.type} von ${user.name} löschen (${days > 1 ? days + ' Tage' : origin.date})?`)
        .catch((_) => false);
      if (res) {
        await this.rota.delete(origins, this._auth);
        this._bc.postMessage('refresh');
        //await deleteFromCloud(this._collection, origins);
        await this._updateView();
      }
    }
    async _deleteLogs(origins: DomData[]) {
      const res = await dialogs.confirm(`Protokoll löschen?`).catch((_) => false);
      if (res) {
        await this.rota.deleteLogs(origins);
      }
    }
    async _onSelectContextMenu({ detail }) {
      console.log('_onMenuSelect detail', detail);
      const { key, action, origins, target, col } = detail;
      //const user = this.rota.users.getUser(target.uid);
      const user = this._userList.find((user) => user.id === target.uid);

      this.selected = [];
      switch (action) {
        case 'update':
          return this._contextMenuUpdateCell(origins, target);
        case 'select':
          return await this._showDialog(col, target, user);
        case 'delete':
          return this._deleteCell(origins, user);
        case 'deleteLog':
          return this._deleteLogs(origins);
        case 'note':
          return dialogs.userNote(user, target.note, target.date, this);
        case 'count':
          return await this.rota.toggleCount(origins[0]);
        default:
          return console.error('Not found', action);
      }
    }

    async _dialogUpdateOnCallWithLocum(uid: string, { date, type }) {
      const tasks = [{ update: { date, uid, type } }]
      this.dispatchEvent(new RotaUpdateEvent('dialogSubmit', 'user', tasks));

      //const origin = await this.rota.getCellContent(date, uid);
      //const target = { date, type };
      //const update = handleDialogSubmit(origin, target);
      //this._handleUpdateUserRota(update);
    }
    async _dialogUpdateCellContent({ date, type, uid }) {
      const tasks = [{ update: { date, uid, type: type.value } }]
      this.dispatchEvent(new RotaUpdateEvent('dialogSubmit', 'user', tasks));

      //const origin = await this.rota.getCellContent(date, uid);
      //const target = { type: type.value };
      //const update = handleDialogSubmit(origin, target);
      //return this._handleUpdateUserRota(update);
    }
    async _setFilter(filter: string) {
      this._filter = filter === 'Filter löschen' ? null : filter;
      await idb.set({ id: 'rota-view', filter: this._filter }, 'settings');
      this._updateView();
    }
    async _onDialogSubmit({ detail }) {
      //this.selected = [];

      const data = {
        uid: detail.get('uid'),
        date: detail.get('date'),
        type: detail.get('type'),
        note: detail.get('note'),
        notes: detail.get('notes'),
        highlight: detail.get('highlight'), // for notes
        soll: detail.get('soll'),
      }

      console.warn(detail);


      if (detail.has('locum')) {
        const locum = detail.get('locum').value;
        return await this._dialogUpdateOnCallWithLocum(locum, data);
      } else if (detail.has('type')) {
        return await this._dialogUpdateCellContent(data);
      } else if (detail.has('notes')) {
        await this.rota.sheetNote(data.date, data.notes, data.highlight);
        // updateView not required as note not added to empty field yet!
      } else if (detail.has('soll')) {
        return await this.rota.sheetSoll(data.date, data.soll);
      } else if (detail.has('note')) {
        await this.rota.rotaNote(data.date, data.uid, data.note);
        // updateView required as note not added to empty field yet!
      } else if (detail.has('filter')) {
        return await this._setFilter(detail.get('filter'));
      } else {
        console.error('Not found logic for action', detail);
      }
    }

    async _onRotaUpdate(e: RotaUpdateEvent) {
      e.stopPropagation();
      await this.rota.handleUpdates(e.gesture, e.col, e.tasks);
    }

    // for new user-cell component
    /*
    async _onRotaUpdate(e: CustomEvent) {
      e.stopPropagation();
      if (e.detail.gesture === 'dragAndDrop') {
        const { targetCell, originCell } = e.detail;

        const target = await this.rota.getCellContent(targetCell.date, targetCell.uid || originCell.uid);
        const origin = await this.rota.getCellContent(originCell.date, originCell.uid);
        const cellContent = { target, origin };

        const update = handleDrop(originCell, targetCell, cellContent);
        if (update) this._handleUpdateUserRota(update);
      } else {
        console.error('not found gesture', e.detail);
      }
    }
    */
    async _onRotaDelete(e: CustomEvent) {
      await this.rota.delete(e.detail.cells, this._auth);
      this._bc.postMessage('refresh');
    }

    /*
    async _handleUpdateUserRota(update) {
      if (update === null) return console.warn('No change!');
      await this.rota.updateUserRota(update, this._auth);
      await this._updateView();


      // BELOW NOT IMPLEMENTED YET

      if (this._highlightRow) {
        this.availability = await this.rota.getAvailableUsers(this._highlightRow);
      }

      this._bc.postMessage('refresh');
      //$('app-notify').new('Erfolgreich geändert');
    }
    */
  }
  return HandlerElement as unknown as Constructor<HandlerInterface> & T;
}
