import { LitElement } from 'lit';
import { Dispatcher } from '../mixins/messenger-mixin';
import { newRoute } from '../state/actions/route.js';

class AppRouter extends Dispatcher(LitElement) {
  constructor() {
    super();
    this.routes = new Map();

    this._onChanged = this._onChanged.bind(this);
  }

  _getRoute(path) {
    const routes = Array.from(this.routes.keys()); // array of all routes found in index.html
    const route = routes.find((r) => r.test(path)); // find current route
    if (!route) return null;
    return route;
  }
  async _onChanged() {
    let path = location.pathname;
    const route = this._getRoute(path);
    if (!route) return elog(`'${path}' not found in router:22`);
    const data = route.exec(path); // find path in route (returns array or null)

    //elog(data.input, { category: 'nav' });

    // get app-view element
    const newView = this.routes.get(route);

    this.dispatchState('route', newRoute(path, newView));

    let outViewPromise = Promise.resolve();

    if (this.currentView) {
      if (this.currentView === newView) return this.currentView.updateView(data);

      outViewPromise = this.currentView.out();
    }

    this.currentView = newView;
    outViewPromise.then((_) => newView.in(data));
  }

  _clearRoutes() {
    this.routes.clear();
  }
  _createRoute(route, view) {
    if (this.routes.has(route)) return elog(route + ' already exists router:53');
    this.routes.set(route, view);
  }
  _createRoutes() {
    for (const view of document.body.querySelectorAll('app-view')) {
      if (!view.route) continue;
      this._createRoute(new RegExp(view.route, 'i'), view);
    }
  }
  connectedCallback() {
    super.connectedCallback();

    this._clearRoutes();
    this._createRoutes();
    this._onChanged();

    window.addEventListener('popstate', this._onChanged);
  }
  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('popstate', this._onChanged);
  }

  go(url, state = null) {
    if (url.startsWith('https://') && !url.startsWith(location.origin)) {
      console.log('external link', location.origin, url);
      return location.assign(url);
    }
    history.pushState(state, null, url);
    window.dispatchEvent(new Event('popstate'));
    //this._onChanged();
  }
  goBack() {
    history.go(-1);
  }
}
customElements.define('app-router', AppRouter);
