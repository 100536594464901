import { FileState } from "state/reducers/file";

export const VIEW_FILE = 'VIEW_FILE';
export const CLOSE_FILE = 'CLOSE_FILE';

export function viewFile(file: FileState) {
  return { type: VIEW_FILE, state: file };
}
export function closeFile() {
  return { type: CLOSE_FILE };
}
