import { css } from 'lit';

const normalize = css`
  :host {
    display: block;
    font-family: inherit;
  }
  * {
    box-sizing: border-box;
  }
  textarea:focus,
  button:focus,
  select:focus,
  input:focus {
    outline: none;
  }
  textarea,
  button,
  input,
  select {
    font-family: inherit;
    font-size: inherit;
    box-sizing: border-box;
    border: 0;
  }
  button {
    text-align: left;
    color: inherit;
    background-color: transparent;
    padding: 0px;
    line-height: 20px;
  }
  button svg-icon {
    pointer-events: none;
  }
  summary {
    outline: none;
  }
  button,
  summary {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .ellipsis span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export default normalize;
