import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { routeEvent } from 'helpers/current-route.js';
import { SyncController } from 'controller/sync.js';
import { signin } from 'helpers/components.js';
import { StateController } from 'controller/state.js';
import { serviceWorkerUpdatePending, updateReady } from 'logic/pwa/sw.js';
import { create } from 'helpers/dom.js';

const category = 'app-view';
const omitSync = new Set(['/file', '/sop', '/reset', '/logs']);
const toggleChildView = new Set(['urlaubsplanung']);

@customElement(category)
class AppView extends LitElement {
  sync = new SyncController(this);

  @state() fileName: string;

  @property() name: string;
  @property() icon: string;
  @property() route: string;

  constructor() {
    super();
    new StateController(this);
  }
  stateChanged(state) {
    this.fileName = state.file.name;
  }

  private _toggleChildView(action) {
    const name = this.name.toLowerCase();
    if (toggleChildView.has(name) === false) return;

    let attrs: {};
    if (name === 'urlaubsplanung') attrs = { collection: 'leaveAllocation' };

    if (action === 'in' && this.firstElementChild === null) {
      create(name + '-view', { attrs, parent: this });
    } else if (action === 'out') {
      this.firstElementChild.remove();
    }
  }
  private async _lazyLoadModules(pathname: string) {
    try {
      switch (pathname) {
        case '/kontakte':
          return import('../views/kontakte-view.js');
        case '/file':
          return import('../views/file-view.js');
        case '/dienste':
          return import('../views/dienste-view.js');
        case '/tagesplan':
          return import('../views/tagesplan-view.js');
        case '/dienstplan':
          return import('../views/dienstplan-view.js');
        case '/entwurf':
          return import('../views/entwurf-view.js');
        case '/urlaub':
          return import('../views/urlaub-view.js');
        case '/urlaubsplanung':
          return import('../views/urlaubsplanung-view.js');
        case '/wunschplan':
          return import('../views/wunschplan-view.js');
        case '/admin':
          return import('../views/admin-view.js');
        case '/profil':
          return import('../views/profil-view.js');
        case '/sop':
          return import('../views/sop-view.js');
        case '/reset':
          return import('../views/reset-view.js');
        case '/logs':
          return import('../views/logs-view.js');
        case '/':
          return import('../views/home-view.js');
        default:
          throw new Error(pathname + ' not found in app-view:50');
      }
    } catch (err) {
      elog(err.message)
    }
  }
  async in(data: RegExpExecArray) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.title = this.name;

    //await authWrapper(data);

    this.classList.add('visible');

    window.dispatchEvent(new Event(routeEvent));

    // replace for fixed dir, split for number of subdirs
    //console.log(data, data[0]);

    const parsedUrl = '/' + data[0].replace('/', '').split('/', 1).join('/');
    if (!this.fileName && !omitSync.has(parsedUrl)) this.sync.start();

    const accessToken = sessionStorage.getItem('accessToken');
    if (!this.fileName && !omitSync.has(parsedUrl) && !accessToken) signin.showLogin();

    //console.log(parsedUrl)
    await this._lazyLoadModules(parsedUrl);

    this._toggleChildView('in');

    // must be below _lazyLoadModules if updating without user input
    // otherwise skipWaiting not activating service worker
    // Possibly due to in-flight requests
    if (await serviceWorkerUpdatePending()) {
      updateReady();
    }
  }
  async out() {
    // dtaa is same as in so not working
    // console.log(`%cExiting ${path}`, `color:gray`);
    this.classList.remove('visible');
    this._toggleChildView('out');
  }
  async updateView(data: RegExpExecArray) {
    //console.log('updateView');
  }

  render() {
    return html`<slot></slot>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppView;
  }
}
