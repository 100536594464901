import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const category = 'app-loading';

@customElement(category)
class AppLoading extends LitElement {
  @property() type: string;

  connectedCallback() {
    super.connectedCallback();
    if (this.type !== 'small' && this.type !== 'inline') document.body.classList.add('noscroll');
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.type !== 'small' && this.type !== 'inline') document.body.classList.remove('noscroll');
  }

  render() {
    switch (this.type) {
      case 'inline':
        return html`
            <div class="flex">
              <div><slot></slot></div>
              <div class="small-loader"></div>
            </div>
          </div>
        `;
      case 'dots':
        return html`
          <div class="loader3"></div>
        `;
      default:
        return html`
          <div class="container flex">
            <div class="loader2"></div>
            <div class="loader2-msg"><slot></slot></div>
          </div>
        `;
    }
  }
  static styles = [
    css`
      :host {
        display: block;
      }
      .flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .container.flex {
        height: 50vh;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .hide-screen .flex .loader2 {
        margin-top: 0px;
        position: unset;
      }
      .loader2-msg {
        padding: 30px 10px;
        color: var(--text1);
        font-size: 0.9rem;
      }
      .loader2,
      .loader2:after {
        border-radius: 50%;
        width: 5em;
        height: 5em;
      }
      .loader2 {
        font-size: 10px;
        margin-top: 50px;
        text-indent: -9999em;
        border: 1.1em solid;
        border-color: var(--blue-6) var(--blue-3) var(--blue-3);
        -webkit-animation: spin 1.1s infinite linear;
        animation: spin 1.1s infinite linear;
      }
      .small-loader {
        position: relative;
        border: 0.2rem solid;
        border-color: #2196f3 transparent transparent;
        -webkit-animation: spin 1.1s infinite linear;
        animation: spin 1.1s infinite linear;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        margin: 0px 10px;
      }
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      .loader3,
      .loader3:before,
      .loader3:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
      }
      .loader3 {
        color: var(--on-face-color);
        font-size: inherit;
        margin-top: -8em;
        margin-left: -2.5em;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .loader3:before,
      .loader3:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .loader3:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .loader3:after {
        left: 3.5em;
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppLoading;
  }
}
