import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { StateController } from 'controller/state.js';

import { router } from 'helpers/components';
import { debounce } from 'helpers/utils';

import normalize from '../css/normalize.js';
import { State } from 'state/reducers.js';
import { ProfileState } from 'state/reducers/user.js';
import { routes } from 'logic/settings.js';

const category = 'app-toolbar';

const ALLOWED_ROUTES_DEFAULT = new Set(['/', '/tagesplan/', '/dienste/', '/dienstplan/', '/kontakte/', '/sop/']);
const ALLOWED_ROUTES_USER = new Set(['/', '/tagesplan/', '/dienste/', '/dienstplan/', '/urlaub/', '/kontakte/', '/sop/']);
const ALLOWED_ROUTES_ROTA = new Set(['/', '/tagesplan/', '/dienste/', '/dienstplan/', '/urlaub/', '/urlaubsplanung/', '/kontakte/', '/sop/']);

function allowRoute(role: string, href: string) {
  switch (role) {
    case 'Admin':
      return true;
    case 'Rota':
      return ALLOWED_ROUTES_ROTA.has(href);
    case 'Benutzer':
      return ALLOWED_ROUTES_USER.has(href);
    default:
      return ALLOWED_ROUTES_DEFAULT.has(href);
  }
}

@customElement(category)
class AppToolbar extends LitElement {
  @state() _btnMenu = 'menu';
  @state() _group = ['account_circle'];
  @state() uid: string;
  @state() role: string;
  @state() profile: ProfileState;
  @state() fileName: string;
  @state() route: {};
  //@state() url: string;
  @state() _navBar = routes.filter((route) => route.display.includes('toolbar'));
  @state() _showNavbar = true;
  @state() _isFixed = false;
  @state() _hasWindowControlsOverlay = false;

  _onGeometryChange: () => void;

  constructor() {
    super();
    new StateController(this);

    console.log(routes);

    const overlayIsVisible = () => (this.classList.add('windowControlsOverlay'));
    this._onGeometryChange = debounce(overlayIsVisible, 100);
  }
  stateChanged(state: State) {
    this.fileName = state.file.name;
    this.route = state.route.view;
    //this.url = state.route.url;
    this.role = state.user ? state.user.role : null;
    this.uid = state.user ? state.user.uid : null;
    this.profile = state.user;
  }
  /*
  protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
    if (_changedProperties.has('route')) {
      if (this.url === '/file/') {
        this.setAttribute('disabled', '');
        document.documentElement.style.setProperty('--toolbar-height', '0px');
      } else {
        this.removeAttribute('disabled');
        document.documentElement.style.setProperty('--toolbar-height', '56px');
      }
    }
  }
  */
  connectedCallback() {
    super.connectedCallback();
    if (location.pathname === '/file/') {
      this.setAttribute('disabled', '');
      document.documentElement.style.setProperty('--toolbar-height', '0px');
    }
    /*
    if ('windowControlsOverlay' in navigator) {
      navigator.windowControlsOverlay.addEventListener('geometrychange', this._onGeometryChange);
      if (navigator.windowControlsOverlay.visible) {
        this.classList.add('windowControlsOverlay');
      }
      console.log('windowControlsOverlay is visible', navigator.windowControlsOverlay);
    }
    */
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    /*
    if ('windowControlsOverlay' in navigator) {
      navigator.windowControlsOverlay.removeEventListener('geometrychange', this._onGeometryChange);
    }
    */
  }

  // combine with _onClickIcon??
  _onClickLink(e) {
    const { href, tagName } = e.target;
    if (tagName !== 'A' || !router) return;
    e.preventDefault();
    router.go(href);
  }
  _onClickIcon({ target }) {
    switch (target.dataset.action) {
      case 'account_circle':
        return router.go('/profil/');
      case 'history':
        return router.go('/logs/');
    }
  }

  async _menuButton() {
    switch (this._btnMenu) {
      case 'menu':
        await import('./app-sidebar');
        const sidebar = document.querySelector('app-sidebar');
        return sidebar.toggleSidebar();
      case 'back':
      case 'close':
        return router.goBack();
      default:
        elog(this._btnMenu + ' toolbar:169');
    }
  }

  renderHamburgerMenu() {
    return html`
      <button name="${this._btnMenu}" @click="${this._menuButton}">
        <svg-icon icon="${this._btnMenu}"></svg-icon>
      </button>
    `;
  }
  renderTags(tag: string) {
    switch (tag) {
      case 'base': {
        if (!this.profile.uid) return;

        const now = new Date();
        const hospital = this.profile.hospital?.[now.getFullYear()]?.[now.getMonth()];
        return html`<span class="tag">${hospital || this.profile.base}</span>`;
      }
      case 'file': {
        if (!this.fileName) return;
        return html`<span class="tag">${this.fileName}</span>`;
      }
    }
  }
  render() {
    return html`
      <header @click="${this._onClickLink}">
        <span class="title">${document.title}</span>
        ${this._navBar.map((item) => {
          if (!allowRoute(this.role, item.href)) return;

          const _class = { selected: item.href === location.pathname };
          return html`<a href="${item.href}" class="${classMap(_class)}">${item.attrs.name}</a>`;
        })}
      </header>
      <section>
        ${this.renderTags('file')}
        ${this.renderTags('base')}
      </section>
      <section class="group" @click="${this._onClickIcon}">
        <dark-mode></dark-mode>
        <button data-action="account_circle"><svg-icon icon="account_circle"></svg-icon></button>
        ${this.uid === '69C45DC0A83211EBA8246F6E8AE5A84F'
          ? html`<button data-action="history"><svg-icon icon="history"></svg-icon></button>`
          : ''}
      </section>
    `;
  }
  static styles = [
    normalize,
    css`
      :host {
        position: sticky;
        top: 0;
        height: var(--toolbar-height);
        padding: 0 5px;
        background: var(--toolbar-surface);
        color: var(--on-background);
        display: flex;
        align-items: center;
        box-shadow: 0 1px 6px 0 var(--toolbar-shadow-color);
        transition: transform 0.25s;
        z-index: 3;
      }
      :host([disabled]) {
        transform: translateY(-60px);
        display: none;
      }

      button {
        position: relative;
        padding: 12px;
        line-height: 0;
      }
      button::before,
      button::after {
        content: '';
        position: absolute;
        pointer-events: none;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 15ms linear, background-color 15ms linear;
        z-index: 1;
        top: calc(50% - 50%);
        left: calc(50% - 50%);
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
      button:hover::before {
        opacity: 0.08;
      }
      button:active::after,
      button:focus::before {
        transition-duration: 75ms;
        opacity: 0.24;
      }
      button:focus::after {
        transition: opacity 150ms linear;
      }
      span.title {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: 0.0125em;
        padding-left: 20px;
        padding-right: 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
        margin-right: auto;
        text-transform: capitalize;
      }
      span.tag {
        padding: var(--space-xxs) var(--space-xs);
        border-radius: var(--space-xxs);
        background: var(--blue-2);
        color: var(--blue-9);
        margin: var(--space-xxs) var(--space-m);
        font-size: 0.9rem;
        font-weight: 500;
      }
      svg-icon.selected {
        color: #2196f3;
      }
      header {
          margin-right: auto;
      }
      :host(.windowControlsOverlay) {
        background: #000;
        color: #fff;
        -webkit-app-region: drag;
        app-region: drag;
      }
      :host(.windowControlsOverlay) button,
      :host(.windowControlsOverlay) a {
        font-size: 0.8rem;
        font-family: system-ui;
        padding: var(--space-xxs);
        margin: var(--space-xxs);
        border-radius: var(--space-xxs);
        color: hsl(var(--hue) 5% 60%);
      }
      :host(.windowControlsOverlay) span.title {
        font-size: 0.9rem;
        color: hsl(var(--hue) 15% 90%);
      }
      dark-mode,
      svg-icon {
        --size: 24px;
      }
      :host(.windowControlsOverlay) dark-mode,
      :host(.windowControlsOverlay) svg-icon {
        --size: 18px;
      }
      span,
      button,
      dark-mode,
      a {
        -webkit-app-region: no-drag;
        app-region: no-drag;
      }


      @media all and (display-mode: fullscreen) {
        :host {
          display: none;
        }
      }
      @media screen and (max-width: 650px) {
        header a {
          display: none;
        }
      }
      @media screen and (min-width: 650px) {
        header span {
          display: none;
        }
        :host {
          justify-content: space-between;
        }
        header a {
          padding: 20px 8px;
          margin: 0px 8px;
          color: inherit;
          text-decoration: none;
          font-family: inherit;
          font-size: 0.9rem;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.0125em;
          transition: box-shadow 0.3s, color 0.3s;
        }
        header a:hover,
        header a.selected {
          box-shadow: 0 -2px 0 var(--highlight-selected-color) inset;
        }
        :host(.windowControlsOverlay) a:hover,
        :host(.windowControlsOverlay) a.selected {
          color: hsl(var(--hue) 30% 80%);
          background: hsl(207deg 90% 20%);
          box-shadow: unset;
        }
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppToolbar;
  }
}
