import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import normalize from '../css/normalize.js';

import { createPromise } from 'helpers/wait.js';
import { emailSendRequest } from 'logic/signin/email.js';
import { Auth } from 'types/server/auth.js';

const category = 'app-signin';

@customElement(category)
class AppSignin extends LitElement {
  @state() _error: string;
  @state() _inProgress: boolean;
  @state() _savedView: HTMLElement;

  _status;

  async showLogin(msg = null) {
    if (this._inProgress) {
      console.warn('already showing signin view', msg);
      return this._status;
    }
    this._inProgress = true;
    this._status = createPromise();

    if (msg) this._error = msg;

    this._savedView = $('app-view.visible');
    if (this._savedView) {
      this._savedView.classList.remove('visible');
    }

    this.classList.add('visible', 'autofill');
    $('app-signin input').focus();

    return this._status;
  }
  hideLogin() {
    this._inProgress = false;
    if (this._savedView) {
      this._savedView.classList.add('visible');
    }
    this.classList.remove('visible', 'autofill');
    if (this._status) this._status.resolve(); // ? null if auto-sigin
  }

  _handleError(res: Auth) {
    console.error({ res });
    switch (res.code) {
      case 'auth/wrong-password':
        return 'Falsches Passwort.';
      case 'auth/user-not-found':
        return 'Benutzername wurde nicht gefunden.';
      case 'auth/too-many-requests':
        return 'Benutzerdaten wurden zu oft falsch eingegeben. Bitte versuchen Sie es später nochmal.';
      default:
        return 'Unbekannter Fehler.'
    }
  }
  _onKeyListener(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this._onSubmit(e);
    }
  }

  async _onSubmit({ target }) {
    target.setAttribute('disabled', '');
    const usrEl = <HTMLInputElement>$('input[name="username"]');
    const pwdEl = <HTMLInputElement>$('input[name="password"]');

    const formData = new FormData();
    formData.set('username', usrEl.value);
    formData.set('password', pwdEl.value);

    try {
      await emailSendRequest(formData);

      target.removeAttribute('disabled');
      this.hideLogin();
    } catch (err) {
      target.removeAttribute('disabled');
      console.error(err);
      this._error = err.message;
    }
  }
  render() {
    return html`
      <header><svg-icon>person</svg-icon></header>
      <section class="error">${this._error || ''}</section>
      <slot @keyup="${this._onKeyListener}"></slot>
      <button class="eSignin" id="eSignin" type="submit" @click="${this._onSubmit}">Anmelden</button>
    `;
  }
  static styles = [
    normalize,
    css`
      :host {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        font-size: 0.9rem;
        font-family: inherit;
        background: #0c161d;
        color: #cecfc9;
        z-index: 99;
        place-content: center;
      }
      main {
        display: grid;
        place-content: center;
      }
      header {
        display: grid;
        place-content: center;
        margin: 20px;
      }
      svg-icon {
        --size: 86px;
      }
      button {
        background: #2196f3;
        color: rgb(255, 255, 255);
        margin: 40px 20px;
        padding: 10px;
        border-radius: 0.3rem;
        text-align: center;
      }
      button:disabled {
        opacity: 0.3;
      }
      .error {
        padding: 10px;
        text-align: center;
        color: #f44336;
        letter-spacing: 0.025rem;
        max-width: 350px;
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppSignin;
  }
}
