/**
 * v1 - final
 */
//import worker from 'web-worker:../worker/worker.js';
//window.__Worker = new Worker(worker);
//window.__Worker = new Worker(worker, { type: 'module' });

//import DataWorker from 'web-worker:../worker/worker.js';
//const worker = new DataWorker();

import { elog as _elog } from './analytics';

export const worker = new Worker(new URL('../worker/web.worker.js', import.meta.url), {
  type: 'module',
});

window.$ = document.body.querySelector.bind(document.body);

const notify = $('app-notify');
window.elog = async (action, options = {}) => {
  /*
    Error.prepareStackTrace = (error, stack) => {
      console.log(error, stack);
      return stack;
    };
  */
  const trace = new Error();
  if (Error.captureStackTrace) Error.captureStackTrace(trace, elog); // removes elog from stack
  //console.log(trace.stack);

  //worker.postMessage({ type: 'elog', action, options });
  _elog(action, options);

  const { category, label, value = '' } = options;

  const message = [category, action, JSON.stringify(label), value];
  if (notify && typeof notify.new === 'function') notify.new(message.join(' '), { category });
};

function onError(e: ErrorEvent) {
  const { message, filename, lineno, colno, error } = e;
  let extra = !colno ? '' : '\ncol: ' + colno;
  extra += !error ? '' : '\nstack: ' + error.stack;
  elog(message, { label: 'onError:122' + filename + ':' + lineno + ' ' + extra });
}
window.addEventListener('error', onError);
window.addEventListener('unhandledrejection', (e) => elog('error:128', { label: e.reason.stack }));
