import dialog from 'helpers/dialog';
import * as dom from 'helpers/dom';

import { leave as listLeaveType, rota as listRotaType } from '../../config/rota';

export function allocation(user, type, date, el) {
  const { id, name, first_name } = user;
  dialog(`${first_name} ${name}`, {
    layout: [
      {
        type: 'list',
        name: 'type',
        value: type ? type.toLowerCase() : '', // value get converted to string, don't use null!
        label: 'date',
        options: el._collection === 'leave' ? listLeaveType : listRotaType,
        settings: { dialog: true, type: 'radio' },
      },
      // metadata
      //{ type: 'hidden', name: 'uid', value: id },
      //{ type: 'hidden', name: 'date', value: date },
    ],
    btns: [],
    options: { uid: id, date },
    parent: el,
  });
}
export function locums(list, type, date, el) {
  dialog(`DKF`, {
    layout: [
      {
        type: 'list',
        name: 'locum',
        value: '',
        options: list,
        settings: { dialog: true, type: 'radio', filter: true },
      },
      // metadata
      //{ type: 'hidden', name: 'date', value: date },
      //{ type: 'hidden', name: 'type', value: type },
    ],
    btns: [],
    options: { date, type },
    parent: el,
  });
}

export function userNote(user, value, date, el) {
  const { id, name, first_name } = user;
  dialog(`${first_name} ${name}`, {
    layout: [
      { type: 'text', className: 'dialog', name: 'note', value: value },
      // change to metadata prop
      //{ type: 'hidden', name: 'uid', value: id },
      //{ type: 'hidden', name: 'date', value: date },
    ],
    options: { uid: id, date },
    parent: el,
  });
}

export function notes(value, date, highlight, el) {
  dialog('Notes', {
    layout: [
      { type: 'text', className: 'dialog', name: 'notes', value, label: null },
      { type: 'checkbox', className: 'highlight', label: 'Highlight', name: 'highlight', value: !!highlight },
    ],
    options: { date },
    parent: el,
  });
}

export function customSoll(value, date, el) {
  dialog('Soll', {
    layout: [{ type: 'number', className: 'dialog', name: 'soll', value, label: null }],
    options: { date },
    parent: el,
  });
}

export function confirm(msg) {
  return new Promise(async (resolve, reject) => {
    await import('../../components/dialog-box.js').catch(_ => console.error(_));
    dom.create('dialog-box', {
      props: {
        layout: [ { type: 'message', value: msg }],
        buttons: [{ label: 'cancel', action: 'close' }, { label: 'ok', action: 'submit' }],
        promise: { resolve, reject },
      },
      parent: document.body,
    });
  });
}

export function filter(filter, el) {
  dialog('Filter', {
    layout: [
      {
        type: 'list',
        name: 'filter',
        label: 'filter',
        value: filter,
        options: ['Vordergrund', 'Hintergrund', 'Teilzeit', 'DKH', 'DKF', 'Filter löschen'],
        settings: { dialog: true, type: 'radio' },
      },
    ],
    parent: el,
  });
}