// TOP => USE PLACEHOLDER to change height and OBSERVER leave at upper most top

import { TableBodyRow } from "types/sheet/table";

export const moduleRef = Symbol('infiniteScroller');

interface Range {
  first: number,
  last: number
  firstVisible: number
  lastVisible: number
}

export class RangeChangedEvent extends Event {
  static eventName = 'rangeChanged';
  first: number;
  last: number;
  firstVisible: number;
  lastVisible: number;

  constructor(range: Range) {
    super(RangeChangedEvent.eventName, {bubbles: true});
    this.first = range.first;
    this.last = range.last;
    this.firstVisible = range.firstVisible;
    this.lastVisible = range.lastVisible;
  }
}
export class VisibilityChangedEvent extends Event {
  static eventName = 'visibilityChanged';
  first: number;
  last: number;

  constructor(range: Range) {
    super(VisibilityChangedEvent.eventName, { bubbles: true });
    this.first = range.first;
    this.last = range.last;
  }
}
export class MonthChangedEvent extends Event {
  static eventName = 'monthChanged';
  scrollIndex: number;

  constructor(scrollIndex: number) {
    super(MonthChangedEvent.eventName, { bubbles: true, composed: true });
    this.scrollIndex = scrollIndex;
  }
}

declare global {
  interface HTMLElementEventMap {
    'rangeChanged': RangeChangedEvent;
    'visibilityChanged': VisibilityChangedEvent;
  }
}

export interface ScrollerHostElement extends HTMLElement {
  [moduleRef]?: Scroller
}

export type ScrollToIndexValue = {index: number, position?: string} | null;

export interface ScrollerConfig {
  hostElement: ScrollerHostElement;
}

export class Scroller {
  protected _hostElement?: ScrollerHostElement;
  private _topAnchor: HTMLElement
  private _bottomAnchor: HTMLElement
  private _scrollElement: HTMLElement

  private _anchorObserver: IntersectionObserver;
  private _childrenObserver: IntersectionObserver;

  private _toBeMeasured: Map<HTMLElement, unknown> = new Map();

  /**
   * Items to render. Set by items.
   */
  private _items: TableBodyRow[] = [];
  private _totalItems: number = 0;
  private _itemsChanged = true;
  private _rangeChanged = true;
  private _visibilityChanged = true;

  private _pendingReflow = false;

  private _currentMonth: number = null;
  private _scrollToMonth: string = null;
  private _scrollInProgress = false;
  private _scrollDir: 'up' | 'down' = null;

  private _bottomMargin: number;
  private _topMargin: number;

  protected _first = -1;
  protected _last = -1;
  private _firstVisible = -1;
  private _lastVisible = -1;
  private _num = 0;

  private _mutationObserver: MutationObserver | null = null;
  private _mutationPromise: Promise<void> | null = null;
  private _mutationPromiseResolver: Function | null = null;
  private _mutationsObserved = false;

  protected _scheduled = new WeakSet();

  get _children(): HTMLElement[] {
    const arr = [];
    let next = this._hostElement!.firstElementChild as HTMLElement;
    while (next) {
      if (next.id) {
        arr.push(next);
      }
      next = next.nextElementSibling as HTMLElement;
    }
    return arr;
  }

  set items(items: Array<unknown> | undefined) {
    if (Array.isArray(items) && items !== this._items) {
      this._itemsChanged = true;
      this._items = items;
      this._totalItems = this._items.length - 1;
      if (this._childrenObserver) this._schedule(this._updateLayout);
    }
  }
  set scrollToIndex(month: number) {
    if (this._currentMonth === month) return;
    const anchor = this._items.find((i) => i.month === month && i.day === 1);
    this._currentMonth = month;
    this._scrollInProgress = true;
    this._scrollToMonth = anchor.date;
    console.log('scrollToIndex', this._scrollToMonth);

    if (this._childrenObserver) this._schedule(this._updateLayout);
  }

  /**
   * this._scheduled uses weakSet to avoid duplications.
   * schedule methods, wait until resolved, then call method (use scroller as ```this``` in method)
   */
  protected async _schedule(method: Function): Promise<void> {
    if (!this._scheduled.has(method)) {
      this._scheduled.add(method);
      await Promise.resolve();
      this._scheduled.delete(method);
      method.call(this);
    }
  }

  constructor(config: ScrollerConfig) {
    if (!config) {
      throw new Error('Scroller constructor requires a configuration object');
    }
    if (config.hostElement) {
      const hostElement = (this._hostElement = config.hostElement);
      hostElement[moduleRef] = this;

      this._scrollElement = document.body.querySelector('app-state');

      this._topAnchor = hostElement.querySelector('.top');
      this._bottomAnchor = hostElement.querySelector('.bottom');
    } else {
      throw new Error('Scroller configuration requires the "hostElement" property');
    }

    //this._onScroll = this._onScroll.bind(this);
  }

  _ioAnchor(entries: IntersectionObserverEntry[]) {
    entries.forEach(async entry => {
      //console.log(entry.target.classList.value, entry.isIntersecting, entry.intersectionRatio);

      // ratio 1 = visible without scroll ie suddenly appears (with page loaded, ...)
      // unless very large element, firefox has entry.intersectionRatio 1 at bottom
      // entry.isIntersecting && entry.intersectionRatio < 1
      if (entry.isIntersecting) {

        /*
        if (entry.target.classList.contains('top')) {
          console.log('top observer visible');
          //this._scrollDir = 'up';
          this._schedule(this._updateLayout);
        } else {
          //this._scrollDir = 'down';
          console.log('bottom observer visible - load elements');
          this._schedule(this._updateLayout);
        }
        */

        const visibileElInRange = this._children.filter((el) => window.innerHeight - el.getBoundingClientRect().top > 0);
        if (visibileElInRange.length === 0) {
          console.error('No visivle in in range!');
          this._topAnchor.style.height = 0 + 'px';
        }
      }

      //this._schedule(this._updateLayout);
    });
  }
  _ioChildren(entries: IntersectionObserverEntry[]) {
    entries.forEach(async entry => {
      const { id } = entry.target;

      if (entry.isIntersecting) {
        entry.target.classList.add('io-visible');
        const [_, month, day] = id.split('-');
        if (+month !== this._currentMonth) {

          //console.error(performance.now(), this._throttle, performance.now() - this._throttle);

          //console.warn(id, 'month change', month, this._currentMonth);

          await this._schedule(this._updateLayout);
        }
      } else {
        entry.target.classList.remove('io-visible');
      }
    });
  }

  _updateLayout() {
    //console.error('updateLayout');
    this._getVisibileItems();
    this._getItems();
    this._reflowIfNeeded();
  }
  async _getVisibileItems() {
    if (this._scrollInProgress) return console.warn('scrollInProgress');

    const { _firstVisible, _lastVisible, _currentMonth } = this;

    const visibleEl = this._children.filter((el) => el.classList.contains('io-visible'));
    this._num = visibleEl.length;

    if (this._num === 0) return console.warn('no visible', _firstVisible, _lastVisible);

    this._firstVisible = +visibleEl[0]?.dataset.index || -1;
    this._lastVisible = +visibleEl[visibleEl.length - 1]?.dataset.index || -1;


    const month = +visibleEl[0]?.dataset.date.split('-')[1];
    if (_currentMonth !== month) {
      this._currentMonth = month;
      this._rangeChanged = true;
      this._notifyMonth();
    }
  }
  _getItems() {
    const { _first, _last } = this;

    const range = this._items.filter((item) => item.month <= this._currentMonth + 6 && item.month >= this._currentMonth - 6);
    if (range.length === 0) return console.warn(`no items for ${this._currentMonth}`);

    //const bottomMargin = this._items.filter((item) => item.month >= this._currentMonth - 1);

    const [first] = range;
    const last = range[range.length - 1];
    this._first = this._items.findIndex((item) => item.date === first.date);
    //this._first = 0;
    this._last = this._items.findIndex((item) => item.date === last.date);

    this._topMargin = this._first * 90;

    if (_first !== this._first || _last !== this._last) {
      console.log('getItems reflow');
      this._pendingReflow = true;
      this._rangeChanged = true;
    } else if (this._firstVisible === -1 && this._lastVisible === -1) {
      console.log('getItems first load', this._first, this._last, this._currentMonth);
      if (this._first > -1 && this._last > -1) {
        //const find = this._children.find((el) => el.id === this._scrollToMonth);
        //if (find) find.scrollIntoView();
      }
    } else {
      return;
      console.log('getItems visibility change', {
        first: this._first,
        last: this._last,
        firstVisible: this._firstVisible,
        lastVisible: this._lastVisible,
        month: this._currentMonth,
      });
    }
  }

  _reflowIfNeeded(force: boolean = false) {
    if (
      force ||
      this._pendingReflow ||
      this._itemsChanged
    ) {
      this._pendingReflow = false;
      this._itemsChanged = false;
      this._reflow();
    }
  }
  _reflow() {
    const { _first, _last, _currentMonth } = this;
    //console.log('_reflow', { _first, _last, _currentMonth });

    this._schedule(this._updateDOM);

    if (this._first === -1 && this._last === -1) {
      // scroll error or first load or empty
    } else if (this._first !== _first || this._last !== _last) {
      // update
    } else {
    }
    //this._scrollIfNeeded();
  }

  /**
   * Called vie Schedule.
   */
  async _updateDOM() {
    //console.log('updateDOM');

    const {_rangeChanged, _itemsChanged} = this;
    if (_rangeChanged || _itemsChanged) {
      const t1 = performance.now();
      this._itemsChanged = false;
      this._rangeChanged = false;
      this._notifyRange();
      await this._mutationPromise;
      const t2 = performance.now();
      //console.error('updateDOM end', t2-t1);

    }

    // dom rendered
    //await new Promise((res) => setTimeout(() => res(), 2000));

    //this._topAnchor.style.height = this._topMargin + 'px';
    //this._bottomAnchor.style.height = this._bottomMargin + 'px';
    //this._hostElement.style.height = 365 * 30 + 'px';

    if (this._scrollToMonth !== null) {
      const find = this._children.find((el) => el.id === this._scrollToMonth);
      if (!find) {
        this.scrollToIndex = 1;
        this._notifyMonth();
        console.error('not found in children', this._scrollToMonth);
        return;
      }

      find.scrollIntoView();
      this._scrollInProgress = false;
      this._scrollToMonth = null;
    }

    this._children.forEach((child) => this._childrenObserver.observe(child));
  }

  /**
   * Listener in infinite-scroller _initialize(). Callback calls render()
   */
  private _notifyRange() {
    this._hostElement!.dispatchEvent(new RangeChangedEvent({
      first: this._first,
      last: this._last,
      firstVisible: this._firstVisible,
      lastVisible: this._lastVisible,
    }));
  }
  private _notifyMonth() {
    this._hostElement!.dispatchEvent(new MonthChangedEvent(this._currentMonth));
  }

  private async _observeMutations() {
    if (!this._mutationsObserved) {
      this._mutationsObserved = true;
      this._mutationPromiseResolver!();
      this._mutationPromise = new Promise(resolve => this._mutationPromiseResolver = resolve);
      this._mutationsObserved = false;
      console.log('_observeMutations rendering rows complete');
    }
  }
  async connected() {
    console.log('Scroller connected');

    this._mutationObserver = new MutationObserver(this._observeMutations.bind(this));
    this._childrenObserver = new IntersectionObserver(this._ioChildren.bind(this), {
      root: this._scrollElement,
      rootMargin: '-250px 0px 0px 0px', // margin for sticky table header (about 250 -> todo: calculate)
      threshold: 0,
    });
    this._anchorObserver = new IntersectionObserver(this._ioAnchor.bind(this), {
      //root: this._scrollElement,
      //rootMargin: '-250px 0px 0px 0px', // margin for sticky table header (about 250 -> todo: calculate)
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
      //threshold: 0,
    });
    this._anchorObserver.observe(this._topAnchor);
    //this._anchorObserver.observe(this._bottomAnchor);

    //document.body.querySelector('app-state').addEventListener('scroll', this._onScroll, { passive: true });

    this._mutationObserver!.observe(this._hostElement!, { childList: true });
    this._mutationPromise = new Promise(resolve => this._mutationPromiseResolver = resolve);


    this._schedule(this._updateLayout);
  }
  disconnected() {
    this._childrenObserver!.disconnect();
    this._anchorObserver!.disconnect();
    //document.body.querySelector('app-state').removeEventListener('scroll', this._onScroll);
  }

  /*
  private _sizeHostElement(size?: ScrollSize | null) {
    // Some browsers seem to crap out if the host element gets larger than a certain size, so we clamp it here
    // (this value based on ad hoc testing in Chrome / Safari / Firefox Mac)
    const max = 8200000;
    const v = size && (size as VerticalScrollSize).height ? Math.min(max, (size as VerticalScrollSize).height) : 0;

    const style = this._hostElement!;
    (style.minHeight as string | null) = v ? `${v}px` : '100%';
  }
  */
   /*
  _onScroll(e) {
    this._scrollPos = e.target.scrollTop;
    if (this._scrollPos === 0) {
      console.warn('top scroll', this._first);

      if (this._first > 0) {
        //this.scrollToIndex = 1;
        //this._notifyMonth();
      }
    }
  }
  */
}
