import { UPDATE_APP_SETTINGS, NEW_ROUTE } from '../actions/app.js';

export interface AppState {
  colorScheme: string;
  file: string;
}

const app = {
  colorScheme: null,
  file: null,
};
export function appReducer(state = app, action) {
  switch (action.type) {
    case UPDATE_APP_SETTINGS:
    case NEW_ROUTE:
      return Object.assign({}, state, action.state);
    default:
      return state;
  }
}
