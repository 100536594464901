import { signin } from 'helpers/components.js';

export const tokenChannel = new BroadcastChannel('tokens');
tokenChannel.onmessage = (e) => {
  const context = sessionStorage.getItem('context');
  console.log('[broadcast]', 'tokens-channel', context);

  if (e.data.action === 'tokens') {
    console.warn('tokens for secondary device');
    sessionStorage.setItem('context', 'secondary');
    sessionStorage.setItem('accessToken', e.data.accessToken);
    sessionStorage.setItem('refreshToken', e.data.refreshToken);
    signin.hideLogin()
  }
  if (e.data.action === 'request-token') {
    if (context === 'primary') {
      console.warn('broadcasting tokens');
      const accessToken = sessionStorage.getItem('accessToken');
      const refreshToken = sessionStorage.getItem('refreshToken');
      tokenChannel.postMessage({ action: 'tokens', accessToken, refreshToken });
    }
  }
};
tokenChannel.postMessage({ action: 'request-token' });