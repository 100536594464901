import { StateController } from 'controller/state';
import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import { setColorScheme } from 'logic/pwa/color-scheme.js';
import { State } from 'state/reducers';

import normalise from '../css/normalize';

const category = 'dark-mode';

@customElement(category)
class DarkMode extends LitElement {
  @state() private _colorScheme: string;

  constructor() {
    super();
    new StateController(this);
    this._colorScheme = null;
  }
  stateChanged(state: State) {
    this._colorScheme = state.app.colorScheme;
  }

  _onClick() {
    const newColorScheme = this._colorScheme === 'dark' ? 'light' : 'dark';
    setColorScheme(newColorScheme);
  }
  render() {
    return html`
      <button @click="${this._onClick}">
        <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M11.1,12.08C8.77,7.57,10.6,3.6,11.63,2.01C6.27,2.2,1.98,6.59,1.98,12c0,0.14,0.02,0.28,0.02,0.42 C2.62,12.15,3.29,12,4,12c1.66,0,3.18,0.83,4.1,2.15C9.77,14.63,11,16.17,11,18c0,1.52-0.87,2.83-2.12,3.51 c0.98,0.32,2.03,0.5,3.11,0.5c3.5,0,6.58-1.8,8.37-4.52C18,17.72,13.38,16.52,11.1,12.08z"/></g><path d="M7,16l-0.18,0C6.4,14.84,5.3,14,4,14c-1.66,0-3,1.34-3,3s1.34,3,3,3c0.62,0,2.49,0,3,0c1.1,0,2-0.9,2-2 C9,16.9,8.1,16,7,16z"/></g></g></svg>
      </button>
    `;
  }
  static styles = [
    normalise,
    css`
      :host {
        display: inline-block;
        font-family: inherit;
        fill: var(--on-background);
      }
      button {
        width: var(--size);
        height: var(--size);
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: DarkMode;
  }
}
