const options = { bubbles: true, composed: true };
class ToastEvent extends Event {
  constructor(message, detail = {}) {
    super('toast', options); // event type
    this.message = message;
    this.detail = detail;
  }
}
class StateEvent extends Event {
  constructor(name, action = {}) {
    super('state', options); // event type
    this.name = name;
    this.action = action;
  }
}
/**
 * One-way communication to provider
 */
export const Dispatcher = (baseElement) =>
  class extends baseElement {
    dispatchToast(message, detail) {
      this.dispatchEvent(new ToastEvent(message, detail));
    }
    dispatchState(name, action) {
      this.dispatchEvent(new StateEvent(name, action));
    }
  };
