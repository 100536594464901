import { UserIndexedDB } from 'controller/idb/users.js';
import { View } from 'logic/calendar.js';
import { ServerRow } from 'types/server/rota.js';
import { VIEW_FILE, CLOSE_FILE } from '../actions/file.js';

export interface FileState {
  name: string;
  version: number;
  title: string;
  bz: number;
  data: ServerRow[];
  view: View[];
  users: UserIndexedDB[];
  rows: Map<string, ServerRow>;
  handle: FileSystemHandle;
  lastModified: number;
}
const file: FileState = {
  name: null,
  version: null,
  title: null,
  bz: null,
  data: [],
  users: [],
  view: [],
  rows: new Map(),
  handle: null,
  lastModified: null,
};
export function fileReducer(state = file, action) {
  switch (action.type) {
    case VIEW_FILE:
      return Object.assign({}, state, action.state);
    case CLOSE_FILE:
      return file;
    default:
      return state;
  }
}
