import { store } from 'helpers/components';

import { ReactiveController, ReactiveControllerHost } from 'lit';

interface Host {
  stateChanged: (state: {}) => void;
}

export class StateController implements ReactiveController {
  host: ReactiveControllerHost & Host;
  _storeUnsubscribe: () => void;

  constructor(host: ReactiveControllerHost & Host) {
    this.host = host;
    host.addController(this);
  }
  hostConnected() {
    this._storeUnsubscribe = store.subscribe(() => this.stateChanged(store.getState()));
    this.stateChanged(store.getState());
  }
  hostDisconnected() {
    this._storeUnsubscribe();
  }

  stateChanged(state: {}) {
    this.host.stateChanged(state);
  }

  dispatch(name: string, action: any) {
    return store.dispatch(name, action);
  }
}
