import { ReactiveController, ReactiveControllerHost } from 'lit';
import { RotaUpdateEvent } from 'mixins/handler-mixin';
import { Column } from 'types/sheet/update';

let draggedItem: HTMLElement;

/**
 * Event order: dragstart => dragleave => dragenter => drop => dragend
 */
export class DragController implements ReactiveController {
  host: ReactiveControllerHost;

  private toolTip: HTMLElement;

  constructor(host: ReactiveControllerHost) {
    this.host = host;
    host.addController(this);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  hostConnected() {}

  private toggleTooltip(target: HTMLElement) {
    const td = target.closest('td');
    if (!td) return;

    if (this.toolTip) {
      this.toolTip.removeAttribute('style');
      this.toolTip = null;
      return;
    }

    this.toolTip = td.querySelector('.tooltip');
    if (this.toolTip) {
      this.toolTip.style.display = 'none';
    }
  }
  private toggleHoverColor(el: HTMLElement) {
    if (!el.tagName) return; // firefox

    const li = el.closest('li');
    if (!li) return;

    if (li.hasAttribute('style')) {
      li.removeAttribute('style');
      li.querySelectorAll('div').forEach((div) => div.removeAttribute('style'));
    } else {
      li.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
      li.querySelectorAll('div').forEach((div) => (div.style.pointerEvents = 'none'));
    }
  }

  onDragStart({ target }) {
    draggedItem = target;
    this.toggleTooltip(target);
  }
  onDragLeave(e: DragEvent) {
    e.preventDefault();
    this.toggleHoverColor(e.target as HTMLElement);
  }
  onDragEnter(e: DragEvent) {
    e.preventDefault();
    this.toggleHoverColor(e.target as HTMLElement);
  }
  /**
   * Element is being dragged over a valid drop target (every few hundred milliseconds).
   */
  onDragOver(e: DragEvent) {
    e.preventDefault();
  }
  onDrop(e: DragEvent) {
    const target = e.target as HTMLElement;

    this.toggleHoverColor(target);

    const { date, type } = target.dataset;

    // cannot use target for oncall col => has no uid or uid is that of user already assigned
    // therefore, swapping user from user col not suppotred yet
    const uid = draggedItem.dataset.uid;

    const { col } = draggedItem.dataset;
    if (col === 'user' && uid !== target.dataset.uid) {
      return elog(`${draggedItem.dataset.type} kann nicht in eine andere Benutzerspalte geschoben werden!`);
    } else if (col === 'header' && target.dataset.col === 'oncall') {
    } else if (col !== target.dataset.col) {
      return elog(`Benutzer kann nicht von ${col} in die Spalte ${target.dataset.col} verschoben werden!`);
    }

    const cells = [];

    if (col === 'header') {
      cells.push({ update: { uid, date, type } });
    } else {
      cells.push({
        origin: {
          date: draggedItem.dataset.date,
          uid: draggedItem.dataset.uid,
          type: draggedItem.dataset.type,
        },
        target: { date, uid, type },
      });
    }

    target.dispatchEvent(new RotaUpdateEvent('dragAndDrop', col as Column, cells));

    /*
    const detail = {
      gesture: 'dragAndDrop',
      originCell: {
        col: draggedItem.dataset.col,
        uid: draggedItem.dataset.uid,
        date: draggedItem.dataset.date,
        type: draggedItem.dataset.type,
      },
      targetCell: { col, uid, date, type },
    }
    console.log(detail);

    target.dispatchEvent(customEvent('rotaupdate', detail));

    this.toggleHoverColor(target);
    */
  }
  /**
   * Target element is the dragged item (from ```dragstart```), not the dropped element
   */
  onDragEnd({ target }) {
    draggedItem = null;
    this.toggleTooltip(target);
  }
}
