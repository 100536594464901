import { customEvent } from 'helpers/events';
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const category = 'menu-category';

declare global {
  interface HTMLElementTagNameMap {
   [category]: MenuCategory;
  }
}

@customElement(category)
class MenuCategory extends LitElement {
  @property() name = '';

  constructor() {
    super();
    this.addEventListener('pointerenter', this._onPointerEnter);
    this.addEventListener('click', this._toggleMenu);
  }
  private _openMenu() {
    location.replace('#' + this.id);
  }
  private _closeMenu() {
    location.replace('#');
  }
  /**
   * ```pointerevent``` is the same as ```mouseenter``` but allows use of pointerType (mouse, touch, ...)
   */
  private _onPointerEnter(e: PointerEvent) {
    if (location.hash && e.pointerType === 'mouse') {
      this.dispatchEvent(customEvent('menu', this.id));
      this._openMenu();
    }
  }
  private _toggleMenu() {
    if (location.hash === `#${this.id}`) {
      this._closeMenu();
      this.dispatchEvent(customEvent('menu', null));
    } else {
      this.dispatchEvent(customEvent('menu', this.id));
      this._openMenu();
    }
  }

  render() {
    return html`
      <app-button>${this.name}</app-button>
      <slot></slot>
    `;
  }

  static styles = [
    css`
      :host {
        display: block;
        touch-action: none;
        border-radius: var(--space-xxs);
        z-index: 4;
      }
      :host([open]) {
        background: var(--theme-hover-surface);
        color: var(--theme-hover-text);
      }
      app-button {
        --gap: var(--space-xs);
        display: block;
        font-size: 0.8rem;
        font-weight: var(--font-weight2);
      }
      app-button.keydown {
        --text-decoration: underline;
      }
      slot {
        display: none;
      }
      :host([open]) slot {
        display: block;
        position: absolute;
        background: var(--surface2);
        color: var(--text1);
        font-size: 0.8rem;
        padding: var(--space-xs) 0;
        border-radius: var(--space-xs);
        box-shadow: var(--shadow-4);
      }
      :host([open]) slot:before {
        content: '';
        position: fixed;
        top: env(titlebar-area-height, var(--titlebar-height-fallback));
        left: 0;
        height: 100%;
        width: 100%;
      }
      ::slotted(app-button) {
        --text-transform: none;
        --line-height: 1.3;
        display: block;
        position: relative;
        min-width: 15ch;
        font-size: 0.8rem;
        font-weight: 400;
      }
      ::slotted(app-button:hover) {
        background: var(--hover);
        opacity: 1;
      }
      ::slotted(app-button[disabled]) {
        opacity: var(--disabled);
        pointer-events: none;
      }
    `,
  ];
}
