import 'logic/init';
import 'logic/performance';
import 'logic/signin/bc-tokens';

import './components/app-state';
import './components/app-notify';
import './components/app-view';
import './components/app-signin';
import './components/app-router';
import './components/dark-mode';
import './components/app-button';
import './components/menu-category';
import './components/app-titlebar';
import './components/app-toolbar';
import './components/app-tapbar';
import './components/app/app-loading';

import './components/rota/cell.js';
import './components/rota/tooltip.js';

import { postLogs } from 'logic/analytics';
import { registerServiceWorker } from 'logic/pwa/sw';

(function run() {
  import('./components/svg-icon');
  import('./components/layout-card');

  //worker.postMessage({ category: 'postLogs' });
  registerServiceWorker();
  postLogs();
}());
